<template>
    <nav class="at768:text-sm">
        <Popover class="relative inline-block text-left" v-slot="{ open }">
            <PopoverButton
                @click="lockScroll(open)"
                class="inline-flex w-full justify-center pt-1 text-xs text-neutral-600 hover:text-black at1024:text-sm"
                data-testid="auth-menu-button"
            >
                <slot name="menu-button">
                    <user-icon
                        class="h-6 w-6 at768:h-5 at768:w-5"
                        :class="open ? 'hidden at768:inline-block' : ''"
                    ></user-icon>
                </slot>
                <x-icon
                    class="w-6 cursor-pointer at768:hidden"
                    :class="open ? 'visible' : 'hidden'"
                    @click="
                        lockScroll(true);
                        close();
                    "
                ></x-icon>
                <span class="sr-only">Account</span>
            </PopoverButton>
            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <PopoverPanel
                    v-slot="{ close }"
                    class="fixed left-0 top-auto z-10 mt-2 h-full w-full origin-top-right overscroll-y-contain border-0 border-black bg-white px-6 pb-8 pt-2 at768:absolute at768:-right-8 at768:bottom-auto at768:left-auto at768:top-full at768:h-auto at768:w-96 at768:rounded-lg at768:border-2 at768:px-8 at1024:-right-3"
                >
                    <div class="nav-items flex flex-col space-y-2">
                        <span
                            class="absolute right-8 top-0 hidden h-4 w-4 -translate-y-1/2 rotate-45 border-2 border-white border-l-black border-t-black bg-white at768:block at1024:right-[12px]"
                        ></span>
                        <div
                            class="!mb-3 flex items-center justify-between border-b border-gray-300 pb-4"
                        >
                            <div>
                                Hello,
                                <span class="font-semibold">{{
                                    userName
                                }}</span>
                            </div>
                        </div>
                        <a
                            v-if="hasOndemandAccess"
                            :href="
                                serviceRouter.storefrontCatalogUrl('ondemand')
                            "
                            target="_blank"
                        >
                            <desktop-computer-icon></desktop-computer-icon>
                            On-demand events
                        </a>
                        <a
                            :href="
                                serviceRouter.eventManagerUrl('account/inbox')
                            "
                            target="_blank"
                        >
                            <inbox-icon></inbox-icon>
                            Inbox
                        </a>
                        <a
                            :href="
                                serviceRouter.eventManagerUrl(
                                    'account/dashboard#requests'
                                )
                            "
                            target="_blank"
                        >
                            <calendar-icon></calendar-icon>
                            Pending requests
                        </a>
                        <a
                            :href="
                                serviceRouter.eventManagerUrl(
                                    'account/dashboard'
                                )
                            "
                            target="_blank"
                        >
                            <calendar-icon></calendar-icon>
                            Orders
                        </a>
                        <a
                            :href="
                                serviceRouter.eventManagerUrl(
                                    'account/wishlist'
                                )
                            "
                            target="_blank"
                        >
                            <heart-icon></heart-icon>
                            {{ wishlistLabel }}
                        </a>
                        <a
                            :href="
                                serviceRouter.eventManagerUrl(
                                    'account/branding'
                                )
                            "
                            target="_blank"
                        >
                            <cog-icon></cog-icon>
                            Add your branding
                        </a>
                        <a
                            :href="
                                serviceRouter.eventManagerUrl(
                                    'account/landing-pages'
                                )
                            "
                            target="_blank"
                        >
                            <template-icon></template-icon>
                            Multi-event landing pages
                        </a>
                        <a
                            :href="
                                serviceRouter.eventManagerUrl(
                                    'account/reporting'
                                )
                            "
                            target="_blank"
                        >
                            <document-report-icon></document-report-icon>
                            Reporting
                        </a>
                        <a
                            :href="
                                serviceRouter.eventManagerUrl('account/user')
                            "
                            target="_blank"
                        >
                            <user-circle-icon></user-circle-icon>
                            My account
                        </a>
                        <button
                            @click.prevent="
                                () => {
                                    logout();
                                    close();
                                }
                            "
                            class="group flex w-full items-center"
                            data-testid="auth-logout-button"
                        >
                            <logout-icon></logout-icon>
                            Log out
                        </button>
                    </div>
                </PopoverPanel>
            </transition>
        </Popover>
    </nav>
</template>

<script setup>
import { useUserInfo } from '@/composables/useUserInfo';
import { useWishlist } from '@/composables/useWishlist';
import lockScroll from '@/helpers/lockScroll';
import { ServiceRouter } from '@/service-router';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import {
    CalendarIcon,
    CogIcon,
    DesktopComputerIcon,
    DocumentReportIcon,
    HeartIcon,
    InboxIcon,
    LogoutIcon,
    TemplateIcon,
    UserCircleIcon,
    UserIcon,
    XIcon,
} from '@heroicons/vue/outline';
import { computed } from 'vue';

const serviceRouter = new ServiceRouter();
const { userInfo, logout } = useUserInfo();
const { wishlist } = useWishlist();

const wishlistLabel = computed(() =>
    wishlist.value?.total_count
        ? `Wishlist (${wishlist.value.total_count})`
        : 'Wishlist'
);
const userName = computed(() => userInfo.value?.firstname);
const hasOndemandAccess = computed(
    () => userInfo.value?.authorized_storefronts?.includes('ondemand') ?? false
);
</script>
<style scoped>
nav .nav-items {
    a {
        @apply flex w-full cursor-pointer items-center;
    }
    a,
    button {
        @apply py-1 hover:underline;
    }
    a > svg,
    button > svg {
        @apply mr-4 inline-block h-5 w-5;
    }
}
</style>

<template>
    <DisclosureButton
        as="div"
        v-if="product.hasShipments && product.host"
        class="group"
    >
        <h4 class="group-hover:underline">Shipping &amp; delivery</h4>
        <slot></slot>
    </DisclosureButton>
</template>

<script setup>
import { DisclosureButton } from '@headlessui/vue';
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped></style>

<template>
    <div v-if="reviews.length > 0" id="host-reviews" class="mt-16">
        <div aria-hidden="true" class="waves-line-black h-px opacity-25"></div>
        <h3 class="sr-only">Reviews</h3>
        <div
            class="mb-6 mt-12 at480:flex at480:items-center at480:justify-between at480:space-x-6"
        >
            <div class="mb-4 flex items-center text-xl font-medium at480:mb-0">
                <star-icon
                    class="mr-2 h-[18px] w-[18px] text-orange"
                ></star-icon>
                <span class="sr-only">Average rating:</span>
                {{ ratingScore }} ({{ ratingCount }}
                {{ ratingCount === 1 ? 'review' : 'reviews' }})
            </div>
            <select
                v-model="sort"
                @change="getReviews(1)"
                :disabled="reviewsLoading"
                id="reviews-sort"
                class="rounded border-gray-300 py-2 pl-3 pr-10 text-sm"
            >
                <option
                    v-for="(label, value) in sorts"
                    :key="value"
                    :value="value"
                    class="px-2 py-8"
                >
                    {{ formatSortLabel(value) }}
                </option>
            </select>
        </div>
        <ol
            class="grid gap-8 at720:grid-cols-2 at1440:gap-16"
            :class="{ 'opacity-25': reviewsLoading }"
        >
            <li v-for="review in reviews" class="text-[15px]">
                <h3 class="font-semibold text-gray-850">
                    {{ review.author_name }}
                    <time class="font-normal text-gray-600"
                        ><span v-if="review.author_name" aria-hidden="true"
                            >- </span
                        >{{ review.review_date_relative_text }}</time
                    >
                </h3>
                <p class="mt-2">
                    <span class="rating-stars" :class="getRatingClass(review)">
                        <span class="sr-only"
                            >Rated: {{ review.rating }} stars</span
                        >
                    </span>
                </p>
                <p class="mt-2 text-gray-600">
                    {{ review.comment }}
                </p>
                <p class="mt-2" v-if="review?.listing">
                    <span class="sr-only">Event:</span>
                    <a
                        :href="review.listing.url"
                        class="text-[13px] font-semibold text-gray-850"
                        >{{ review.listing.name }}</a
                    >
                </p>
            </li>
        </ol>
        <simple-pagination
            class="mb-16 mt-8 flex justify-center at1024:mt-12"
            :current-page="page"
            :pages="pageCount"
            @page-next-requested="
                getReviews(page + 1);
                scrollToReviews();
            "
            @page-prev-requested="
                getReviews(page - 1);
                scrollToReviews();
            "
        ></simple-pagination>
        <component
            :is="'script'"
            v-html="jsonLd"
            type="application/ld+json"
        ></component>
    </div>
</template>

<script setup>
import { useReviewFetch } from '@/composables/useReviewFetch';
import { StarIcon } from '@heroicons/vue/solid';
import { inject, onMounted } from 'vue';
import VueScrollTo from 'vue-scrollto';
import SimplePagination from '../SimplePagination';

const host = inject('host');
const ratingScore = host.ratingScore.toFixed(1);
const ratingCount = host.ratingCount;
const {
    reviews,
    reviewsLoading,
    getReviews,
    page,
    pageCount,
    sorts,
    sort,
    formatSortLabel,
    jsonLd,
} = useReviewFetch('webapi/hosts/' + host.uuid + '/reviews', {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': host.uuid,
    name: host.name,
});

const getRatingClass = (review) => {
    return 'stars-' + review.rating;
};
const scrollToReviews = () => {
    VueScrollTo.scrollTo('#host-reviews', 200, {
        force: false,
        offset: 40,
    });
};

onMounted(() => {
    getReviews();
});
</script>

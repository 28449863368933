<template>
    <div>
        <p>{{ prefix }} {{ visibleItems.join(separator) }}</p>
        <a
            v-if="items.length > limit"
            @click.prevent="opened = !opened"
            href="#"
            class="flex flex-row items-center space-x-1 underline underline-offset-1 hover:no-underline"
        >
            <chevron-down-icon
                class="inline-block w-4"
                :class="opened ? 'rotate-180' : ''"
            ></chevron-down-icon>
            <span>
                {{
                    opened ? 'see less' : '+' + (items.length - limit) + ' more'
                }}
            </span>
        </a>
    </div>
</template>
<script setup>
import { ChevronDownIcon } from '@heroicons/vue/outline';
import { computed, ref } from 'vue';

const props = defineProps({
    items: {
        type: Array,
        required: true,
    },
    limit: {
        type: Number,
        required: false,
        default: 12,
    },
    opened: {
        type: Boolean,
        required: false,
        default: false,
    },
    separator: {
        type: String,
        required: false,
        default: ', ',
    },
    prefix: {
        type: String,
        required: false,
        default: '',
    },
});
const opened = ref(props.opened);
const visibleItems = computed(() => {
    return opened.value ? props.items : props.items.slice(0, props.limit);
});
</script>

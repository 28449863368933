<template>
    <div
        class="bg-pattern-squiggly-1 mb-16 bg-peach"
        :style="logoBackgroundVars"
    >
        <div
            class="relative mx-auto h-[120px] px-4 at480:px-6 at1024:max-w-standard at1024:px-8"
        >
            <figure
                class="absolute bottom-0 h-[105px] w-[105px] translate-y-[40%] rounded-full border-2 border-gray/25 bg-white p-1"
                v-if="host.thumbnailUrl"
            >
                <img
                    :src="host.thumbnailUrl"
                    :alt="host.name + ' logo'"
                    class="rounded-full"
                    loading="eager"
                />
            </figure>
        </div>
    </div>
    <div
        class="mx-auto px-4 at480:px-6 at1024:flex at1024:max-w-standard at1024:gap-8 at1024:px-8"
    >
        <div class="at1024:w-[300px] at1024:shrink-0">
            <h1 class="mb-5 text-3xl font-medium tracking-[-0.01em] text-blue">
                {{ host.name }}
            </h1>
            <ul class="mb-5 flex space-x-1.5 text-xl" v-if="host.ratingCount">
                <li class="flex items-center font-medium">
                    <star-icon class="mr-2 w-6 text-orange"></star-icon>
                    <span class="sr-only">Average rating:</span>
                    {{ host.ratingScore.toFixed(1) }}
                </li>
                <li>
                    <span class="sr-only">Number of ratings:</span>
                    ({{ host.ratingCount }})
                </li>
            </ul>
            <div
                aria-hidden="true"
                class="waves-line-black h-px opacity-25"
            ></div>
            <p
                v-if="host.description"
                class="my-5 text-[15px] leading-6 tracking-[-0.02em] text-gray-600"
            >
                {{ description }}
                <span class="mt-4 block" v-if="descriptionTruncated">
                    <button
                        @click.stop.prevent="descriptionTruncated = false"
                        class="font-medium text-gray-900 underline"
                    >
                        Read their story
                    </button>
                </span>
            </p>
            <div
                aria-hidden="true"
                class="waves-line-black h-px opacity-25"
            ></div>
            <div
                v-if="Object.values(host.languages).length > 0"
                class="relative mt-5 text-[15px] tracking-[-0.02em]"
            >
                <dt>
                    <div class="absolute h-12 w-12">
                        <translate-icon class="h-6 w-6"></translate-icon>
                    </div>
                    <p
                        class="ml-10 text-sm font-medium leading-6 text-black at1024:text-base"
                    >
                        Languages supported
                    </p>
                </dt>
                <dd class="ml-10 mt-0.5 text-sm text-gray at1024:text-base">
                    <expandable-set
                        :items="Object.values(host.languages)"
                    ></expandable-set>
                </dd>
            </div>
        </div>
        <div class="mt-8 at1024:mt-0">
            <h2 class="mb-6 text-2xl font-medium">Events</h2>
            <template v-if="host.products.length > 0">
                <div
                    class="grid grid-cols-1 gap-x-6 gap-y-10 at720:grid-cols-2 at1440:grid-cols-3"
                >
                    <product-tile
                        v-for="product in host.products"
                        :product="product"
                    ></product-tile>
                </div>
                <host-reviews></host-reviews>
            </template>
            <p v-else class="italic">
                This host doesn't have any active listings at the moment.
            </p>
        </div>
    </div>
</template>
<script setup>
import { assetUrl } from '@/cdn';
import { TranslateIcon } from '@heroicons/vue/outline';
import { StarIcon } from '@heroicons/vue/solid';
import ExpandableSet from 'components/Global/ExpandableSet.vue';
import ProductTile from 'components/ProductList/ProductTile';
import { computed, provide, ref } from 'vue';
import HostReviews from './HostDetail/Reviews';

const props = defineProps({
    host: {
        type: Object,
        required: true,
    },
});

provide('host', props.host);

const descriptionTruncated = ref(true);
const description = computed(() =>
    descriptionTruncated.value
        ? props.host.description.slice(0, 100) + '...'
        : props.host.description
);
const logoBackgroundVars = {
    '--bg-pattern-squiggly-1':
        'url(' + assetUrl('/static/img/bg-pattern-squiggly-1.svg') + ')',
};
</script>
<style>
.bg-pattern-squiggly-1 {
    background-image: var(--bg-pattern-squiggly-1);
}
</style>

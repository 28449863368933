<template>
    <Menu as="div" class="relative">
        <a
            :href="item.uri"
            :target="item.target"
            :class="item.class"
            :title="item.title"
        >
            <menu-button
                as="span"
                :href="item.uri"
                :key="item.id"
                class="nav-top inline-flex cursor-pointer items-center px-1 pt-1 font-medium text-neutral-600"
                :class="active ? 'active' : ''"
            >
                {{ item.label }}
            </menu-button>
        </a>
        <transition
            enter-active-class="transition duration-50 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-50 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <menu-items
                v-if="item.children"
                :key="item.id"
                class="menu-items top-10 mt-1.5 w-max origin-top-right rounded-lg rounded-md border border-black bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none at768:absolute at768:left-1/2 at768:z-20 at768:-translate-x-1/2"
            >
                <div
                    class="absolute left-0 top-0 flex w-full -translate-y-1/2 items-center justify-center"
                >
                    <span
                        class="hidden h-4 w-4 rotate-45 rounded-sm border border-transparent border-l-black border-t-black bg-white at768:block"
                        role="none"
                        :class="{
                            'bg-gray-200': firstItemHovered,
                        }"
                    ></span>
                </div>
                <ul class="flex flex-col" role="none">
                    <menu-item
                        as="li"
                        v-for="(i, k) in item.children"
                        class="menu-item inline-block py-6 hover:bg-gray-200"
                        :class="{
                            'hover:rounded-t-md': k == 0,
                            'hover:rounded-b-md': k == item.children.length - 1,
                        }"
                        @mouseenter="k == 0 ? (firstItemHovered = true) : null"
                        @mouseleave="k == 0 ? (firstItemHovered = false) : null"
                    >
                        <a
                            :href="i.uri"
                            :title="i.title"
                            class="group inline-block flex w-full items-center px-6 text-sm font-normal text-black"
                            :class="i.class"
                            :target="i.target"
                        >
                            <div class="mr-2">
                                <h4 class="text-base font-medium">
                                    {{ i.label }}
                                </h4>
                                <p
                                    v-if="i.subtitle"
                                    class="mt-1 max-w-[260px] text-gray-500"
                                >
                                    {{ i.subtitle }}
                                </p>
                            </div>
                            <chevron-right-icon
                                class="ml-auto h-7 stroke-[1.5]"
                            ></chevron-right-icon>
                        </a>
                    </menu-item>
                </ul>
            </menu-items>
        </transition>
    </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronRightIcon } from '@heroicons/vue/outline';
import { ref } from 'vue';

export default {
    name: 'Flyout',
    setup() {
        return {
            firstItemHovered: ref(false),
        };
    },
    props: {
        item: {
            required: true,
            type: Object,
        },
        active: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    components: {
        ChevronRightIcon,
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
    },
};
</script>

<style scoped>
.active {
    @apply text-black underline decoration-2 underline-offset-[12px];
}
.menu-item {
    @apply border-b border-gray-300;
}
.menu-item:last-of-type {
    @apply border-none;
}
</style>

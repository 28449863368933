<template>
    <div data-testid="event-wizard-step-results">
        <main>
            <h1>Calculating results...</h1>
            <loading-spinner
                class="mx-auto mt-6"
                width="60px"
            ></loading-spinner>
        </main>
    </div>
</template>
<script setup>
import { useEventWizard } from '@/composables/useEventWizard';
import LoadingSpinner from 'components/Global/LoadingSpinner.vue';
import { onMounted } from 'vue';

const { submit } = useEventWizard();
const $emit = defineEmits(['close']);

onMounted(() => {
    setTimeout(() => {
        $emit('close');
        submit();
    }, 2000);
});
</script>

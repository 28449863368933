<template>
    <div>
        <div class="mb-2 flex flex-col space-y-1">
            <h3 class="text-base font-semibold">Instant book</h3>
            <span class="text-sm font-normal text-gray-400"
                >Events that will book instantly.</span
            >
        </div>

        <div class="flex flex-row items-center space-x-1.5">
            <input
                id="grid-filter-instant-book"
                type="checkbox"
                @change="hasFilter = !hasFilter"
                class="rounded border-gray-300"
                :checked="hasFilter"
            />
            <label for="grid-filter-instant-book" class="text-sm"
                >Show only instant book events</label
            >
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref, watch } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { applyFilter } = useCollectionFilters();
const hasFilter = ref(!!props.current);

watch(hasFilter, (newValue) => {
    applyFilter('instant_book', newValue || null);
});
</script>

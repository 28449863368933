<template>
    <div
        id="filterable-product-list"
        :class="{
            'filters-left': showLeftHandFilters,
            'filters-modal': !showLeftHandFilters,
            'filters-with-map': showMap,
        }"
    >
        <div
            class="mx-auto mb-4 mt-8 flex flex-row items-center at1024:max-w-standard"
        >
            <div class="flex items-center gap-x-3">
                <product-filter-button
                    class="modal-filter-launcher min-w-[110px] shrink-0 rounded border border-gray-300 px-3 py-2 text-sm font-medium hover:border-black"
                    :class="{
                        'outline outline-2 -outline-offset-1 outline-black':
                            hasFiltersApplied,
                    }"
                    data-testid="modal-filters-open-button"
                ></product-filter-button>
                <span
                    class="left-filters-header hidden items-center space-x-1 font-medium"
                >
                    <adjustments-horizontal-icon
                        class="h-5 w-5"
                    ></adjustments-horizontal-icon>
                    <span>All filters</span>
                    <a
                        v-if="hasFiltersApplied"
                        @click.prevent="clearFilters"
                        href="#"
                        class="flex items-center space-x-0.5 pl-0.5 text-xs font-normal text-gray-600 underline hover:text-black hover:no-underline"
                        data-testid="left-filters-clear-button"
                        ><span>clear all</span
                        ><x-circle-icon class="w-3"></x-circle-icon>
                    </a>
                </span>

                <lat-lng-bounds-filter-toggle
                    v-if="latLngBoundsFilter"
                    v-model="showMap"
                    :use-solid-icons="false"
                    class="min-w-[110px] shrink-0 rounded border border-gray-300 px-3 py-2 text-sm font-medium hover:border-black"
                    data-testid="modal-filters-show-hide-map-button"
                ></lat-lng-bounds-filter-toggle>
            </div>

            <product-sort
                v-if="products.length > 1"
                :sort-options="filterOptions.sorts"
                :default="filters.sort || defaultSort"
                @add-filter="applyFilter"
                class="ml-auto"
            ></product-sort>
        </div>

        <div ref="listingContent" class="flex items-start at900:gap-x-4">
            <div class="w-full">
                <div class="flex items-start">
                    <product-filter-grid
                        :loading="loading"
                        class="left-filters-grid hidden w-[280px] shrink-0 flex-col space-y-3"
                    ></product-filter-grid>

                    <div v-if="loading" class="w-full">
                        <placeholder-grid
                            class="placeholder-grid tiles-grid"
                            :class="conditionalTileGridClasses"
                        ></placeholder-grid>
                    </div>

                    <product-grid
                        v-else-if="products.length > 0"
                        :products="products"
                        :currency="productListMeta.currency"
                        class="modal-filter-grid tiles-grid"
                        :class="conditionalTileGridClasses"
                        @product-hovered="notifyProductHovered"
                        data-testid="product-grid"
                    ></product-grid>

                    <no-results
                        v-else
                        class="w-full rounded border border-gray-300 px-4 py-10 text-center text-sm at900:block at1200:px-10"
                        :class="{ 'map-visible col-span-3': showMap }"
                    >
                    </no-results>
                </div>

                <simple-pagination
                    v-if="!loading && products.length > 0"
                    :current-page="currentPage"
                    :pages="pageCount"
                    @page-next-requested="nextPage()"
                    @page-prev-requested="prevPage()"
                    class="mt-8 flex justify-center at1024:mt-12"
                    data-testid="filter-pagination"
                ></simple-pagination>
            </div>

            <KeepAlive>
                <lat-lng-bounds-filter
                    v-if="showMap"
                    v-model="latLngBounds"
                    :center="latLngBoundsFilter.center"
                    :zoom="latLngBoundsFilter.zoom"
                    :products="products"
                    :highlighted-product="hoveredProduct"
                    class="!sticky top-2 h-[80vh] w-full shrink-0 at900:h-[700px] at900:w-[35%] at900:min-w-[375px]"
                ></lat-lng-bounds-filter>
            </KeepAlive>
        </div>

        <lat-lng-bounds-filter-toggle
            id="mapToggle"
            v-if="latLngBoundsFilter"
            v-model="showMap"
            class="sticky bottom-11 left-1/2 w-fit -translate-x-1/2 rounded-2xl bg-black px-4 py-2 text-sm text-white transition-opacity duration-200"
            :class="{
                'opacity-100': showMapToggle,
                'opacity-0': !showMapToggle,
            }"
            data-testid="floating-show-hide-map-button"
        ></lat-lng-bounds-filter-toggle>
    </div>
</template>

<script>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import {
    trackItemListFilterEvent,
    trackViewItemListEvent,
} from '@/helpers/gtm';
import {
    AdjustmentsHorizontalIcon,
    XCircleIcon,
} from '@heroicons-v2/vue/24/outline';
import axios from 'axios';
import NoResults from 'components/ProductList/NoResults';
import LatLngBoundsFilter from 'components/ProductList/ProductFilter/AdditionalFilters/LatLngBoundsFilter';
import LatLngBoundsFilterToggle from 'components/ProductList/ProductFilter/LatLngBoundsFilterToggle';
import ProductFilterButton from 'components/ProductList/ProductFilterButton';
import ProductFilterGrid from 'components/ProductList/ProductFilterGrid.vue';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import { ref, shallowRef, toValue } from 'vue';
import VueScrollTo from 'vue-scrollto';
import UsesPagination from '../mixins/usePagination';
import PlaceholderGrid from './ProductList/PlaceholderGrid';
import ProductGrid from './ProductList/ProductGrid';
import ProductSort from './ProductList/ProductSort';
import SimplePagination from './SimplePagination';

export default {
    mixins: [UsesPagination],
    components: {
        AdjustmentsHorizontalIcon,
        LatLngBoundsFilter,
        LatLngBoundsFilterToggle,
        NoResults,
        PlaceholderGrid,
        ProductFilterButton,
        ProductFilterGrid,
        ProductGrid,
        ProductSort,
        SimplePagination,
        XCircleIcon,
    },
    setup(props) {
        const {
            filterOptions,
            appliedFilters,
            appliedVisibleFilters,
            applyFilter,
            applyFilters,
            clearFilters,
        } = useCollectionFilters(
            {
                categories: props.childCategories,
                ...props.options,
            },
            props.availableFilters,
            props.defaultSort
        );
        return {
            showMap: ref(false),
            showMapToggle: ref(false),
            latLngBounds: ref([]),
            products: shallowRef([]),
            productListMeta: ref({}),
            loading: ref(true),
            ready: ref(false), // used to reduce CLS by forcing min-height on the component while events are initially loading
            paginating: ref(false),
            hoveredProduct: ref(null),
            mapFilters: ['lat_lng_bounds'],
            debouncedLoadProducts: null,
            filters: appliedFilters,
            filterOptions,
            appliedVisibleFilters,
            applyFilter,
            applyFilters,
            clearFilters,
        };
    },
    props: {
        options: {
            type: Object,
            required: true,
            validator(value) {
                return (
                    value.hasOwnProperty('durations') &&
                    value.hasOwnProperty('languages') &&
                    value.hasOwnProperty('maxPricePerPerson') &&
                    value.hasOwnProperty('sorts') &&
                    value.hasOwnProperty('videoPlatforms')
                );
            },
        },
        defaultSort: {
            type: String,
            required: false,
        },
        resultsEndpointUrl: {
            type: String,
            required: true,
        },
        pageSize: {
            type: [Number],
            required: false,
            default: 0,
        },
        currentCategory: {
            type: [Object, Boolean],
            required: false,
            default: false,
        },
        childCategories: {
            type: [Array, Boolean],
            required: false,
            default: false,
        },
        availableFilters: {
            type: Array,
            required: false,
            default: [],
        },
        latLngBoundsFilter: {
            type: Object,
            required: false,
            default: {},
            validator(value) {
                return (
                    value.hasOwnProperty('center') &&
                    value.center.hasOwnProperty('lat') &&
                    value.center.hasOwnProperty('lng')
                );
            },
        },
        source: {
            type: String,
            required: false,
            default: null,
        },
        filterType: {
            type: String,
            required: false,
            default: 'modal',
        },
    },
    created() {
        this.debouncedLoadProducts = debounce(this.loadProducts, 750, {
            trailing: false,
            leading: true,
        });
        this.initialLoad();
        window.onpopstate = (e) => {
            this.initialLoad();
        };
    },
    mounted() {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    this.showMapToggle = entry.isIntersecting;
                });
            },
            {
                threshold: 0.05,
                rootMargin: '-150px 0px 0px 0px',
            }
        );
        observer.observe(this.$refs.listingContent);
    },
    watch: {
        showMap(newValue) {
            if (newValue) {
                this.applyMapFilter();
            } else {
                this.applyFilter('lat_lng_bounds', null);
            }
        },
        latLngBounds() {
            if (!this.loading) {
                this.applyMapFilter();
            }
        },
        filters: {
            handler(newValue) {
                const purged = this.removeIncompatibleFilters('modal', {
                    ...pick(this.filters, this.mapFilters),
                    ...newValue,
                });
                if (
                    Object.keys(purged).length !==
                    Object.keys(this.filters).length
                ) {
                    this.applyFilters(purged);
                } else {
                    this.debouncedLoadProducts();
                }
            },
            deep: true,
        },
    },
    computed: {
        selectedCategorySlug() {
            return this.currentCategory.slug;
        },
        hasFiltersApplied() {
            return toValue(this.appliedVisibleFilters).length > 0;
        },
        incompatibleFilters() {
            return {
                modal: {
                    physical_type: {
                        value: 'will come to you',
                        incompatible: 'lat_lng_bounds',
                        callback: () => {
                            this.showMap = false;
                        },
                    },
                },
                map: {
                    lat_lng_bounds: {
                        value: '*',
                        incompatible: 'physical_type',
                    },
                },
            };
        },
        showLeftHandFilters() {
            return !this.showMap && this.filterType === 'left';
        },
        conditionalTileGridClasses() {
            return this.showMap
                ? 'hidden at900:grid at900:grid-cols-3'
                : this.showLeftHandFilters
                  ? 'at900:grid-cols-2 at1024:grid-cols-3'
                  : '';
        },
    },
    methods: {
        initialLoad() {
            if (this.filters['lat_lng_bounds']) {
                this.showMap = true;
                this.latLngBounds = this.filters['lat_lng_bounds'];
            }
            this.debouncedLoadProducts();
        },
        async loadProducts() {
            const payload = {
                category: this.selectedCategorySlug,
                page_size: this.pageSize,
                ...this.filters,
            };
            const params = new URLSearchParams();
            params.append('filters', JSON.stringify(payload));

            this.loading = true;

            if (this.paginating) {
                this.paginating = false;
                VueScrollTo.scrollTo('#filterable-product-list', 250, {
                    force: false,
                    offset: -30,
                });
            }

            try {
                const response = await axios.get(this.resultsEndpointUrl, {
                    params,
                });
                this.products = response.data.products || [];
                this.productListMeta = response.data.meta || {};
                this.trackGtmEvents();
            } catch (e) {
                console.error(e);
                this.products = [];
                this.productListMeta = {
                    page_count: 1,
                    total_results: 0,
                };
            } finally {
                this.loading = false;
                this.ready = true;
            }
        },
        removeIncompatibleFilters(typeBeingAdded, filters) {
            try {
                const removals = this.incompatibleFilters[typeBeingAdded];
                Object.keys(removals).forEach((key) => {
                    if (
                        filters[key] &&
                        (removals[key]['value'] === '*' ||
                            removals[key]['value'] === filters[key])
                    ) {
                        delete filters[removals[key]['incompatible']];
                        if (removals[key]['callback']) {
                            removals[key]['callback']();
                        }
                    }
                });
            } catch (e) {
                console.error(e);
            }

            return filters;
        },
        applyMapFilter() {
            if (this.latLngBounds?.length !== 4) {
                return;
            }
            const toApply = this.removeIncompatibleFilters('map', {
                ...this.filters,
                ...{ lat_lng_bounds: this.latLngBounds },
            });
            this.applyFilters(toApply);
        },
        prevPage() {
            this.paginating = true;
            this.applyFilter('page', this.currentPage - 1);
        },
        nextPage() {
            this.paginating = true;
            this.applyFilter('page', this.currentPage + 1);
        },
        trackGtmEvents() {
            if (!this.source) {
                this.source = 'events_filter';
            }

            // custom filtering event, ignoring the initial page load filter
            if (this.ready) {
                trackItemListFilterEvent(this.source, {
                    category: this.selectedCategorySlug,
                    filters: Object.keys(this.filters).join(','),
                    page_size: this.pageSize,
                    page: this.currentPage,
                    total_results: this.productListMeta.total_results,
                });
            }

            // GA4 ecommerce event
            trackViewItemListEvent(this.source, this.products);
        },
        notifyProductHovered(isHovered, product) {
            this.hoveredProduct = isHovered ? product : null;
        },
    },
};
</script>

<style scoped>
:deep(#product-list-no-results .view-filters-button) {
    @apply at960:hidden;
}

:deep(#product-list-no-results.map-visible .view-filters-button) {
    @apply block;
}

#filterable-product-list {
    &.filters-left:not(.filters-with-map) {
        .modal-filter-launcher {
            @apply at960:hidden;
        }
        .left-filters-header,
        .left-filters-grid {
            @apply at960:flex;
        }
    }
}
</style>

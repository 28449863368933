<template>
    <div class="relative">
        <div class="mb-2 flex flex-row items-center space-x-1.5">
            <h3 class="text-base font-semibold">How long is your event?</h3>
            <a
                v-if="current"
                href="#"
                @click.prevent="clearSelections"
                class="text-xs"
                >clear</a
            >
        </div>

        <expandable-list
            class="flex flex-col space-y-2 text-sm"
            :items="durations"
        >
            <template #item="{ idx, label, value }">
                <div class="flex items-center space-x-1.5">
                    <input
                        :id="`grid-filter-duration-${idx}`"
                        @change="handleSelection(value, $event)"
                        type="checkbox"
                        :value="value"
                        class="rounded border-gray-300"
                        :checked="
                            findIndex(selectedDurations, (v) => v === value) >
                            -1
                        "
                    />
                    <label :for="`grid-filter-duration-${idx}`">{{
                        label
                    }}</label>
                </div>
            </template>
        </expandable-list>

        <button
            v-if="showApplyButton"
            @click="applyFilters"
            type="button"
            class="absolute bottom-0 right-0 rounded border border-gray-300 bg-white px-2 py-2 text-sm hover:bg-gray-100/90"
        >
            Apply
        </button>
    </div>
</template>

<script setup>
import findIndex from 'lodash/findIndex';
import { computed, ref } from 'vue';

import { useCollectionFilters } from '@/composables/useCollectionFilters';
import ExpandableList from 'components/Global/ExpandableList.vue';
import isEqual from 'lodash/isEqual';

const { filterOptions } = useCollectionFilters();
const { applyFilter } = useCollectionFilters();
const durations = filterOptions.durations;

const props = defineProps({
    current: {
        required: false,
        default: null,
    },
});

const selectedDurations = ref(props.current ? [...props.current] : []);

const handleSelection = (duration, event) => {
    if (event.target.checked) {
        selectedDurations.value.push(duration);
        selectedDurations.value.sort();
    } else {
        try {
            let index = findIndex(
                selectedDurations.value,
                (v) => v === duration
            );
            if (index !== -1) {
                selectedDurations.value.splice(index, 1);
            }
        } catch (e) {}
    }
};

const applyFilters = () => {
    applyFilter('durations', selectedDurations.value);
};

const clearSelections = () => {
    selectedDurations.value = [];
    applyFilters();
};

const showApplyButton = computed(() => {
    return !isEqual(selectedDurations.value, props.current || []);
});
</script>

<style scoped></style>

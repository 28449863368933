import { ServiceRouter } from '@/service-router';
import axios from 'axios';
import { ref, watch } from 'vue';

const serviceRouter = new ServiceRouter();
const userInfo = ref({});
const isAuthenticating = ref(false);
const isAuthenticated = ref(null);
const xsrfToken = ref('');

// onAuthenticated and onUnauthenticated are optional hooks that are called when the user is authenticated or
// unauthenticated, respectively
export function useUserInfo(onAuthenticated = null, onUnauthenticated = null) {
    if (onAuthenticated || onUnauthenticated) {
        watch(isAuthenticated, (newValue) => {
            if (newValue && onAuthenticated) {
                onAuthenticated();
            } else if (!newValue && onUnauthenticated) {
                onUnauthenticated();
            }
        });
        if (isAuthenticated.value === true && onAuthenticated) {
            onAuthenticated();
        } else if (isAuthenticated.value === false && onUnauthenticated) {
            onUnauthenticated();
        }
    }
    fetchUserInfo();
    return { userInfo, isAuthenticated, isAuthenticating, xsrfToken, logout };
}

async function fetchUserInfo() {
    if (isAuthenticated.value !== null || isAuthenticating.value) {
        // the request has already been made
        return;
    }
    isAuthenticating.value = true;
    try {
        const response = await axios.get(
            serviceRouter.catalogUrl('webapi/auth/verify'),
            {
                withCredentials: true,
                validateStatus: function (status) {
                    // don't error for 403
                    return (status >= 200 && status < 300) || status === 403;
                },
            }
        );
        if (response.status === 200) {
            userInfo.value = response.data;
            isAuthenticated.value = true;
            xsrfToken.value =
                userInfo.value.token ?? response.headers['x-xsrf-token'];
            delete userInfo.value.token;
        } else {
            userInfo.value = {};
            isAuthenticated.value = false;
        }
    } finally {
        isAuthenticating.value = false;
    }
}

async function logout() {
    if (!isAuthenticated.value) {
        return;
    }
    if (!xsrfToken.value) {
        console.error('XSRF token not set -- cannot log out.');
        return;
    }
    await axios.post(
        serviceRouter.eventManagerUrl('webapi/auth/logout'),
        {},
        {
            withCredentials: true,
            headers: {
                'X-CSRF-TOKEN': xsrfToken.value,
            },
        }
    );
    userInfo.value = {};
    isAuthenticated.value = false;
}

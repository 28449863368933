<template>
    <nav>
        <template v-for="item in items">
            <sub-navigation-item
                :item="item"
                class="relative inline-block text-left"
            ></sub-navigation-item>
        </template>
    </nav>
</template>

<script>
import SubNavigationItem from './SubNavigationItem';

export default {
    name: 'SubNavigation',
    props: {
        items: {
            required: true,
            type: Object,
        },
    },
    components: {
        SubNavigationItem,
    },
};
</script>

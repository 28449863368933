<template>
    <div>
        <product-tile
            v-for="(p, index) in products"
            :product="p"
            @mouseenter="$emit('productHovered', true, p)"
            @mouseleave="$emit('productHovered', false, p)"
            :data-testid="'product-tile-' + index"
        ></product-tile>
    </div>
</template>

<script setup>
import ProductTile from './ProductTile';

const props = defineProps({
    products: {
        type: Array,
        required: true,
    },
});

const $emit = defineEmits(['productHovered']);
</script>

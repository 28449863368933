<template>
    <div>
        <Listbox v-model="filter">
            <ListboxButton
                data-testid="search-box-filters"
                class="inline-flex h-full shrink-0 cursor-pointer items-center justify-center space-x-1 rounded-l-md border-r border-gray-400 bg-gray-200 px-3 text-left focus:border-gray-400 focus:outline-none"
            >
                <component :is="currentOption.icon" class="mr-1 w-4 shrink-0" />
                <span class="hidden w-full text-nowrap at600:inline">{{
                    currentOption.label
                }}</span>
                <ChevronUpDownIcon
                    class="h-5 w-5 shrink-0 justify-self-end text-gray-400"
                />
            </ListboxButton>

            <transition
                leave-active-class="transition duration-100 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-1 ring-1 ring-black/5 focus:outline-none"
                >
                    <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="option in options"
                        :key="option.value"
                        :value="option.value"
                        as="template"
                    >
                        <li
                            :class="[
                                active
                                    ? 'bg-blue-100 text-blue-900'
                                    : 'text-gray-900',
                                'relative flex cursor-pointer select-none items-center space-x-2 px-4 py-2',
                            ]"
                            :data-testid="
                                'search-filter-option-' + option.value
                            "
                        >
                            <component :is="option.icon" class="w-4 shrink-0" />
                            <span
                                :class="[
                                    selected ? 'font-medium' : 'font-normal',
                                    'block text-nowrap',
                                ]"
                                >{{ option.label }}</span
                            >
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </Listbox>
    </div>
</template>

<script setup>
import { useActiveNavGroup } from '@/composables/useActiveNavGroup';
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from '@headlessui/vue';
import { ChevronUpDownIcon } from '@heroicons-v2/vue/24/solid';
import { computed } from 'vue';

const { searchTypeOptions: options } = useActiveNavGroup();

const filter = defineModel({ type: String, default: 'virtual' });
const currentOption = computed(() => {
    return options.find((option) => option.value === filter.value);
});
</script>

<style scoped></style>

<template>
    <span v-if="product.host?.leadTime">
        {{ product.host.leadTime }} day lead time
    </span>
</template>

<script setup>
import { inject } from 'vue';

const product = inject('product');
</script>

import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { deconstructUrlParams } from '@/composables/usePushHistoryState';
import { computed, reactive, ref } from 'vue';
import VueScrollTo from 'vue-scrollto';

const { availableFilters, filterOptions, applyFilters } =
    useCollectionFilters();
const filters = reactive({});
const steps = reactive([
    { component: 'budget', name: 'Guest count' },
    { component: 'category', name: 'Event type' },
    { component: 'date', name: 'Date' },
    { component: 'results', name: 'Results' },
]);
const stepIndex = ref(0);
const step = computed(() => steps[stepIndex.value]);

const canGoBack = computed(() => stepIndex.value > 0);
const canContinue = computed(() => stepIndex.value < steps.length - 1);

const gotoStep = (step) => {
    for (const [index, value] of steps.entries()) {
        if (value.name === step) {
            stepIndex.value = index;
            break;
        }
    }
};
const previousStep = () => {
    stepIndex.value -= 1;
};
const nextStep = () => {
    stepIndex.value += 1;
};
const pushFilter = (key, value) => {
    filters[key] = value;
};
const getFilter = (key) => {
    return filters[key] || null;
};
const submit = () => {
    stepIndex.value = 0;
    applyFilters(filters, true);
    VueScrollTo.scrollTo('#filterable-product-list', 250, {
        force: false,
        offset: -30,
    });
};

export function useEventWizard(initializeFilters = false) {
    if (initializeFilters) {
        const initialFilters = deconstructUrlParams(
            ['budget', 'categories'],
            [],
            ['event_date']
        );
        for (const key in initialFilters) {
            pushFilter(key, initialFilters[key]);
        }
        // remove steps that are not applicable
        function removeStep(component) {
            const index = steps.findIndex(
                (value) => value.component === component
            );
            if (index > -1) {
                steps.splice(index, 1);
            }
        }
        if (!availableFilters.includes('budget')) {
            removeStep('budget');
        }
        if (!availableFilters.includes('event_date')) {
            removeStep('date');
        }
        if ((filterOptions?.categories.length ?? 0) === 0) {
            removeStep('category');
        }
    }
    return {
        steps,
        stepIndex,
        step,
        canGoBack,
        canContinue,
        previousStep,
        nextStep,
        gotoStep,
        pushFilter,
        getFilter,
        submit,
    };
}

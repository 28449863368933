<template>
    <info-modal>
        <div class="flex flex-col space-y-6">
            <header class="flex flex-row items-center space-x-4 text-left">
                <truck-icon class="h-8 w-8 stroke-1"></truck-icon>
                <h3 class="font-semibold">Free shipping</h3>
            </header>

            <main class="space-y-6 text-sm">
                <p>
                    This event includes a shipment to each guest's address.
                    You'll have to provide addresses
                    {{ product.host.leadTime }} days prior to the event to allow
                    for shipping and delivery.
                </p>
                <p>
                    See
                    <a
                        class="font-semibold underline underline-offset-2"
                        href="#"
                        @click.prevent="$emit('linkToDescriptionClick')"
                        >shipping and delivery</a
                    >
                    for more details.
                </p>
            </main>
        </div>
    </info-modal>
</template>

<script setup>
import { TruckIcon } from '@heroicons/vue/outline';
import InfoModal from 'components/Global/InfoModal';

defineProps({
    product: {
        type: Object,
        required: true,
    },
});

const $emit = defineEmits(['linkToDescriptionClick']);
</script>

<style scoped></style>

<template>
    <button @click.prevent="copyToClipboard">
        <span
            class="flex flex-row items-center space-x-2"
            :class="{ 'text-green-700': copied }"
        >
            <clipboard-check-icon
                v-if="copied"
                class="h-5 w-5"
            ></clipboard-check-icon>
            <clipboard-copy-icon v-else class="h-5 w-5"></clipboard-copy-icon>
            <span v-if="buttonLabel && !copied">{{ buttonLabel }}</span>
            <span v-else-if="buttonLabel && copied">Copied!</span>
        </span>
    </button>
</template>

<script setup>
import { ClipboardCheckIcon, ClipboardCopyIcon } from '@heroicons/vue/outline';
import { ref, watch } from 'vue';

const props = defineProps({
    buttonLabel: {
        type: String,
        required: false,
        default: 'Copy link',
    },
    text: {
        type: String,
        required: true,
    },
});

const copied = ref(false);

watch(copied, function (value) {
    if (value === true) {
        setTimeout(() => (copied.value = false), 2000);
    }
});

const copyToClipboard = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        copied.value = true;
        return navigator.clipboard.writeText(props.text);
    }
    return Promise.reject('The Clipboard API is not available.');
};
</script>

<style scoped></style>

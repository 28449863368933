<template>
    <base-modal v-slot="{ close }">
        <div class="flex max-w-[1024px] flex-col at768:flex-row">
            <!-- Right column: -->
            <div class="p-8 at768:order-2 at768:w-1/2">
                <template v-if="!submitted">
                    <p class="text-gray-500">
                        Our team will get back to you within one business day
                        during normal business hours ET
                    </p>
                    <form
                        @submit.prevent="handleForm"
                        aria-live="polite"
                        class="modal-form"
                    >
                        <p v-if="hasErrors" class="errors">
                            Sorry, but there was a problem submitting your
                            information. Please try again later.
                        </p>
                        <ul>
                            <li class="left !mt-0">
                                <label for="quote-firstname">
                                    <span aria-hidden="true">*</span>
                                    First name
                                </label>
                                <input
                                    type="text"
                                    name="firstname"
                                    v-model="form.fields.firstname"
                                    id="quote-firstname"
                                    required
                                    autocomplete="off"
                                />
                            </li>
                            <li class="right">
                                <label for="quote-lastname">
                                    <span aria-hidden="true">*</span>
                                    Last name
                                </label>
                                <input
                                    type="text"
                                    name="lastname"
                                    v-model="form.fields.lastname"
                                    id="quote-lastname"
                                    required
                                    autocomplete="off"
                                />
                            </li>
                            <li class="left">
                                <label for="quote-email">
                                    <span aria-hidden="true">*</span>
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    v-model="form.fields.email"
                                    id="quote-email"
                                    required
                                    autocomplete="off"
                                />
                            </li>
                            <li class="right">
                                <label for="quote-phone"> Phone </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    v-model="form.fields.phone"
                                    id="quote-phone"
                                />
                            </li>
                            <li class="left">
                                <label for="quote-guest-count">
                                    <span aria-hidden="true">*</span>
                                    Estimated
                                    {{
                                        product.isEvent
                                            ? 'guests count'
                                            : '# of recipients'
                                    }}
                                </label>
                                <input
                                    type="number"
                                    name="number_of_guests"
                                    v-model="
                                        form.fields['TICKET.number_of_guests']
                                    "
                                    id="quote-guest-count"
                                    min="1"
                                    required
                                />
                            </li>
                            <li class="right">
                                <label for="quote-company"> Company </label>
                                <input
                                    type="text"
                                    name="company"
                                    v-model="form.fields.company"
                                    id="quote-company"
                                />
                            </li>
                            <li>
                                <label for="quote-comment">
                                    <span aria-hidden="true">*</span>
                                    Tell us about your
                                    {{ product.isEvent ? 'event' : 'request' }}
                                </label>
                                <textarea
                                    name="content"
                                    v-model="form.fields['TICKET.content']"
                                    class="h-32"
                                    id="quote-comment"
                                    autocomplete="off"
                                    required
                                ></textarea>
                            </li>
                            <li>
                                <button type="submit" :disabled="isProcessing">
                                    Request a quote
                                </button>
                            </li>
                        </ul>
                    </form>
                </template>
                <div
                    v-else
                    class="flex h-full flex-col items-center justify-center"
                >
                    <p class="flex max-w-xs flex-col text-center font-semibold">
                        <check-icon class="h-24 text-green-700"></check-icon>
                        Thanks! We’ve received your submission and will be in
                        touch soon.
                    </p>
                    <p class="mt-8">
                        <button
                            @click.stop.prevent="close"
                            class="rounded-md bg-black px-12 py-2 font-bold text-white"
                        >
                            Close
                        </button>
                    </p>
                </div>
            </div>
            <modal-listing-details
                :product="product"
                class="bg-gray-200 p-8 at768:order-1 at768:mr-0 at768:w-1/2"
            ></modal-listing-details>
        </div>
    </base-modal>
</template>

<script setup>
import { useHubspotForm } from '@/composables/useHubspotForm';
import { useStorefront } from '@/composables/useStorefront';
import { CheckIcon } from '@heroicons/vue/outline';
import BaseModal from 'components/Global/BaseModal.vue';
import { ref } from 'vue';
import ModalListingDetails from '../ModalCommon/ListingDetails.vue';

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
});

const { getStorefront } = useStorefront();
const storefront = getStorefront();
const $emit = defineEmits(['formSubmitted']);
const isProcessing = ref(false);
const hubspotFormId = props.product.isEvent
    ? HUBSPOT_QUOTE_EVENT_FORM_ID
    : HUBSPOT_QUOTE_PRODUCT_FORM_ID;

const { initForm, submitToHubspot, submitted, hasErrors } =
    useHubspotForm(hubspotFormId);

const form = ref({
    fields: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        'TICKET.content': '',
        'TICKET.event_requested_url': window.location.href,
        'TICKET.host_name': props.product.host?.name || '',
        'TICKET.host_email': props.product.host?.email || '',
        'TICKET.number_of_guests': 1,
        'TICKET.storefront': storefront.subdomain,
        'TICKET.subject': props.product.name + ' Quote Request',
        'TICKET.submission_type': 'Request a quote',
        'TICKET.what_experience_did_you_book_with_reelivate_':
            props.product.name,
    },
    context: {
        pageUri: window.location.href,
        pageName: document.title,
    },
});

initForm('Quote Request');

const handleForm = async () => {
    isProcessing.value = true;
    await submitToHubspot(form.value);
    isProcessing.value = false;
    if (!hasErrors) {
        $emit('formSubmitted');
    }
};
</script>

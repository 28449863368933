<template>
    <vue-final-modal
        class="flex justify-center at768:items-start"
        content-class="relative mt-0 flex w-full max-w-none flex-col at768:rounded-lg bg-white at768:mt-16 at768:max-h-[90vh] at768:w-3/4 at768:max-w-[850px]"
        data-testid="event-wizard-modal-container"
    >
        <modal-header></modal-header>
        <component
            :is="StepComponents[step.component]"
            @close="emit('close')"
            class="min-h-[200px] overflow-y-auto"
        ></component>
    </vue-final-modal>
</template>
<script setup>
import { useEventWizard } from '@/composables/useEventWizard';
import { VueFinalModal } from 'vue-final-modal';
import ModalHeader from './EventWizard/ModalHeader.vue';
import StepComponents from './EventWizard/Steps';

const emit = defineEmits(['close']);
const { step } = useEventWizard(true);
</script>
<style scoped>
:deep(main) {
    @apply px-10 py-14;
    h1 {
        @apply mx-auto max-w-4/5 text-center text-xl font-semibold at768:text-2xl at1024:text-3xl;
    }
    h3 {
        @apply mt-2 text-center text-base;
    }
    .content {
        @apply mx-auto max-w-[500px] pt-8;
        label {
            @apply font-semibold;
        }
    }
}
:deep(footer) {
    @apply flex justify-center space-x-4 rounded-b-lg bg-gray-200 p-5;
    button {
        @apply flex items-center space-x-1;
        svg {
            @apply size-4;
        }
    }
}
</style>

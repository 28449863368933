<template>
    <div>
        <div
            class="flex flex-col space-y-4 px-4 at720:px-6 at1024:px-0 at1200:space-y-7"
        >
            <bread-crumbs
                v-if="product.breadcrumbs.length > 0 && isMainStorefront"
                :crumbs="product.breadcrumbs"
                class="text-xs text-gray-500"
            ></bread-crumbs>

            <page-heading :show-copy-button="false" class="at1024:hidden" />
        </div>

        <div
            class="mx-auto mt-4 at1024:mt-8 at1024:grid at1024:max-w-standard at1024:grid-cols-5 at1024:grid-rows-[auto_auto] at1024:gap-x-16"
        >
            <image-gallery
                :images="product.images"
                :video="product.videoUrl"
                :use-tall-layout="product.useTallImageLayout"
                class="px-4 at720:px-6 at1024:col-span-3 at1024:px-0"
                v-model="selectedVariant"
            ></image-gallery>

            <div
                class="mt-8 at1024:relative at1024:col-span-2 at1024:row-span-2 at1024:mt-0"
            >
                <div class="space-y-4 at1024:sticky at1024:top-8">
                    <page-heading
                        :show-copy-button="false"
                        class="hidden gap-y-3 at768:flex-col at768:!items-start at768:!space-x-0 at1024:flex"
                    />

                    <p class="px-4 at1024:px-0">
                        {{ product.shortDescription }}
                    </p>
                    <div class="mt-4 flex flex-col at1024:mt-0">
                        <div
                            class="flex flex-col space-y-4 bg-gray-200 px-4 py-8 at1024:block at1024:bg-white at1024:p-0"
                        >
                            <quote-lead-form
                                v-if="product.isLeadForm"
                            ></quote-lead-form>
                            <template v-else>
                                <product-price
                                    class="flex flex-wrap items-center gap-x-1.5 text-2xl font-semibold text-black at1024:text-xl at1200:text-2xl"
                                    :price="product.price"
                                    :show-shipping="true"
                                >
                                </product-price>
                                <checkout-form
                                    v-if="
                                        product.published &&
                                        (product.isSalable || product.variants)
                                    "
                                    @variant-selected="variantSelected"
                                ></checkout-form>
                                <div v-else class="mt-4 at1200:mt-6">
                                    <p>
                                        Unfortunately, this item is no longer
                                        available.
                                    </p>
                                    <a
                                        v-if="product.primaryCategoryUrl"
                                        :href="product.primaryCategoryUrl"
                                        class="btn-orange button-custom-branded mt-4 block w-full"
                                    >
                                        See similar products
                                    </a>
                                </div>
                            </template>
                        </div>

                        <ask-question
                            v-if="product.published"
                            class="mx-auto my-8 max-w-[450px] px-4 at720:px-8 at1024:w-full at1024:max-w-full at1024:rounded-lg at1024:border at1024:border-gray-300 at1024:p-4 at1200:p-8"
                        ></ask-question>

                        <multi-location-info-box
                            v-if="!product.isLeadForm"
                            :intl-shipping-available="
                                product.shipping.hasInternationalShipping
                            "
                            class="flex items-start space-x-4 bg-blue-300 px-6 py-4 at1024:rounded-lg"
                            ><span class="hidden at1024:inline"
                                >Choose whether you want all of your items going
                                to a single location or to each recipient's
                                address. Don't worry, you can see all of the
                                shipments right in the portal.</span
                            ></multi-location-info-box
                        >
                    </div>
                </div>
            </div>

            <div class="at1024:col-span-3">
                <div class="mt-8 px-4 at720:px-6 at1024:px-0">
                    <listing-descriptions
                        :description-elements="descriptions()"
                        initial-open="all"
                    ></listing-descriptions>
                </div>

                <!-- re-enable when reviews are supported for products -->
                <!--                <product-reviews-->
                <!--                    class="border-t border-gray-300 pt-8"-->
                <!--                ></product-reviews>-->

                <product-faqs
                    v-if="product.faqs.length > 0"
                    :faqs="product.faqs"
                    class="border-t border-gray-300 pt-8"
                >
                    <template #title>
                        <h3
                            class="mb-4 text-base font-medium text-black at1024:text-xl"
                        >
                            Frequently asked questions
                        </h3>
                    </template>
                </product-faqs>
            </div>
        </div>

        <related-products
            :host-products="product.hostProducts"
            :related-products="product.relatedProducts"
        >
            <template #host-products-title> Similar products </template>
        </related-products>

        <slot name="pdp-after"></slot>
    </div>
</template>

<script setup>
import { useStorefront } from '@/composables/useStorefront';
import { trackViewItemEvent } from '@/helpers/gtm';
import CheckoutForm from 'components/ListingDetail/CheckoutForm.vue';
import QuoteLeadForm from 'components/ListingDetail/QuoteLeadForm.vue';
import RelatedProducts from 'components/ListingDetail/RelatedProducts.vue';
import { onMounted, provide, reactive, ref } from 'vue';
import ProductFaqs from './EventDetail/ProductFaqs';
import ProductPrice from './Global/ProductPrice';
import AskQuestion from './ListingDetail/AskQuestion';
import BreadCrumbs from './ListingDetail/BreadCrumbs';
import ListingDescriptions from './ListingDetail/ListingDescriptions';
import MultiLocationInfoBox from './ListingDetail/MultiLocationInfoBox';
import PageHeading from './ListingDetail/PageHeading';
import ListingDescriptionElements from './ProductDetail/Details/Descriptions';
import ImageGallery from './ProductDetail/Media/ImageGallery';

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
});

const product = reactive(props.product);

provide('product', product);
const { isMainStorefront } = useStorefront();

onMounted(() => {
    trackViewItemEvent(product);
});

function descriptions() {
    return props.product.published
        ? ListingDescriptionElements
        : { description: ListingDescriptionElements.description };
}

const selectedVariant = ref({});
function variantSelected(variant) {
    selectedVariant.value = variant;
    for (const property in variant.product) {
        product[property] = variant.product[property];
    }
}
</script>

<style scoped>
:deep(.price .shipping-price) {
    @apply text-base;
}
:deep(.tiles-grid .price .shipping-price) {
    @apply text-xs;
}
</style>

<template>
    <div>
        <div class="mb-2 flex flex-col gap-y-1">
            <h3 class="text-lg font-semibold">Group size</h3>
            <span class="text-sm font-normal text-gray-400"
                >Approximately how many guests are you planning for?</span
            >
        </div>

        <div class="flex flex-row items-center space-x-2">
            <input
                v-model="groupSize"
                @keyup.enter="applyFilters"
                type="number"
                name="group_size"
                min="1"
                id="modal-filter-budget-group_size"
                class="block w-full max-w-[330px] rounded-md border-gray-300 py-3 text-base font-normal at768:text-sm"
                placeholder="Add your group size"
            />
            <button
                @click="applyFilters"
                type="button"
                class="rounded border border-gray-300 bg-white px-2 py-2 text-sm hover:bg-gray-100/90"
                data-testid="modal-filters-budget-apply-price-button"
            >
                Apply
            </button>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});
const { applyFilter } = useCollectionFilters();
const groupSize = ref(props.current);

function applyFilters() {
    const newValue = groupSize.value;
    if (Number.isInteger(newValue) && newValue > 0) {
        applyFilter('group_size', groupSize.value);
    } else if (newValue === '') {
        applyFilter('group_size', null);
    }
}
</script>

import ContentsContent from './Contents/DescriptionContent';
import ContentsHeading from './Contents/DescriptionHeading';
import DescriptionContent from './Description/DescriptionContent';
import DescriptionHeading from './Description/DescriptionHeading';
import ShippingContent from './Shipping/DescriptionContent';
import ShippingHeading from './Shipping/DescriptionHeading';

export default {
    description: {
        content: DescriptionContent,
        heading: DescriptionHeading,
    },
    contents: {
        content: ContentsContent,
        heading: ContentsHeading,
    },
    shipping: {
        content: ShippingContent,
        heading: ShippingHeading,
    },
};

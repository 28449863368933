import { trackGtmEvent } from '@/helpers/gtm';

window.elevent = window.elevent || {};
window.elevent.HsListenerAttached = false;

window.elevent.HsOnConversationsReady = function () {
    if (!window.elevent.HsListenerAttached && window.HubSpotConversations) {
        window.elevent.HsListenerAttached = true;
        window.HubSpotConversations.on('conversationStarted', (payload) => {
            trackGtmEvent('eventTracking', {
                category: 'Chat',
                action: 'Chat Opened',
                label: '',
            });
        });
    }
};
if (window.HubSpotConversations) {
    window.elevent.HsOnConversationsReady();
} else {
    window.hsConversationsOnReady = [window.elevent.HsOnConversationsReady];
}
setTimeout(function () {
    window.elevent.HsOnConversationsReady(); // because hsConversationsOnReady inconsistently fires
    setTimeout(window.elevent.HsOnConversationsReady, 3000); // try once more
}, 3000);

<template>
    <div id="product-list-no-results">
        <div class="flex flex-col gap-y-2">
            <h5 class="text-base font-bold">No exact matches</h5>
            <template v-if="hasFiltersApplied">
                <span class="text-gray-400"
                    >Try changing or resetting your filters</span
                >
                <div
                    class="my-3 flex flex-row items-center justify-center gap-x-4"
                >
                    <button
                        type="reset"
                        class="rounded border border-gray-300 bg-white px-6 py-2 font-bold hover:border-black"
                        @click="clearFilters"
                    >
                        Reset all filters
                    </button>
                    <product-filter-button
                        :show-applied-filter-count="false"
                        class="view-filters-button min-w-[110px] shrink-0 rounded border border-gray-300 px-3 py-2 text-sm font-medium hover:border-black"
                    >
                        <template #button-content>
                            <span class="font-bold">View filters</span>
                        </template>
                    </product-filter-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import ProductFilterButton from 'components/ProductList/ProductFilterButton';
import { computed, toValue } from 'vue';

const { filterOptions, appliedVisibleFilters, clearFilters } =
    useCollectionFilters();
const hasFiltersApplied = computed(
    () => toValue(appliedVisibleFilters).length > 0
);
</script>

import { computed, ref } from 'vue';
const storefront = ref({});

export function useStorefront() {
    const setStorefront = (store) => {
        storefront.value = store;
    };

    const getStorefront = () => {
        return storefront.value;
    };

    const isMainStorefront = computed(() => storefront.value.isMainStorefront);

    return {
        isMainStorefront,
        getStorefront,
        setStorefront,
    };
}

import Budget from './Budget';
import Category from './Category';
import EventDate from './EventDate';
import EventDuration from './EventDuration';
import GroupSize from './GroupSize.vue';
import InstantBook from './InstantBook';
import InternationalShipping from './InternationalShipping';
import KitIncluded from './KitIncluded';
import LiveHosting from './LiveHosting';
import SupportedLanguages from './SupportedLanguages';
import TravelToYou from './TravelToYou';
import VideoPlatforms from './VideoPlatforms';

//order represents the order of appearance in the filter modal
export default {
    physical_type: TravelToYou,
    budget: Budget,
    group_size: GroupSize,
    has_shipments: KitIncluded,
    event_date: EventDate,
    categories: Category,
    durations: EventDuration,
    instant_book: InstantBook,
    international_shipping: InternationalShipping,
    is_hosted: LiveHosting,
    video_platforms: VideoPlatforms,
    lang: SupportedLanguages,
};

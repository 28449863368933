<template>
    <div>
        <div class="mb-2 flex flex-col space-y-1">
            <h3 class="text-base font-semibold">Events with shipments</h3>
            <span class="text-sm font-normal text-gray-400"
                >Each guest receives a package.</span
            >
        </div>

        <div class="flex flex-col space-y-3 text-sm">
            <div class="flex flex-row space-x-1.5">
                <input
                    @change="toggleFilter"
                    type="radio"
                    name="has_shipments"
                    id="grid-filter-shipments-both"
                    value=""
                    :checked="current === null"
                />
                <label for="grid-filter-shipments-both">All Events</label>
            </div>
            <div class="flex flex-row space-x-1.5">
                <input
                    @change="toggleFilter"
                    type="radio"
                    name="has_shipments"
                    id="grid-filter-shipments-yes"
                    value="true"
                    :checked="current === true"
                />
                <label for="grid-filter-shipments-yes"
                    >Events with Shipments</label
                >
            </div>
            <div class="flex flex-row space-x-1.5">
                <input
                    @change="toggleFilter"
                    type="radio"
                    name="has_shipments"
                    id="grid-filter-shipments-no"
                    value="false"
                    :checked="current === false"
                />
                <label for="grid-filter-shipments-no"
                    >Events without Shipments</label
                >
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { applyFilter } = useCollectionFilters();
const filterValue = ref(props.current);
const toggleFilter = (event) => {
    filterValue.value = event.target.value;
    applyFilter(
        'has_shipments',
        filterValue.value === '' ? null : filterValue.value === 'true'
    );
};
</script>

<template>
    <span
        class="flex items-center rounded border border-gray-300 px-2 py-4 font-medium at1024:px-4 at1024:py-6"
    >
        <img :src="imgSrc" :alt="alt" class="mx-auto" />
    </span>
</template>

<script setup>
import { assetUrl } from '@/cdn';
import capitalize from 'lodash/capitalize';

const props = defineProps({
    platform: {
        type: String,
        required: true,
    },
    imgAlt: {
        type: String,
        required: false,
        default: '',
    },
});

const imgSrc = assetUrl(
    `/static/img/conference-platform-logos/${props.platform}.svg`
);
const alt = props.imgAlt || capitalize(props.platform);
</script>

<style scoped></style>

<template>
    <select v-model="model">
        <option v-if="!isRequired">Please Select...</option>
        <option
            v-for="(option, value) in timezonesOrdered"
            :value="option.zone"
            :selected="option.zone === modelValue"
        >
            {{ option.label }}
        </option>
    </select>
</template>

<script setup>
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';

const model = defineModel();

const props = defineProps({
    isRequired: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const timezones = {};
for (const zone of Intl.supportedValuesOf('timeZone')) {
    if (DateTime.local().setZone(zone).isValid) {
        timezones[zone] = {
            label: DateTime.local({ zone }).toFormat('(ZZZZ) z'),
            offset: DateTime.local({ zone }).offset,
            zone: zone,
        };
    }
}
const timezonesOrdered = sortBy(timezones, ['offset', 'label']);
</script>

<style scoped></style>

<template>
    <div>
        <h2 class="text-lg font-medium">{{ headline }}</h2>

        <top-line
            @link-to-description-click="handleDescriptionLinkClick"
            class="mt-4 flex flex-row flex-wrap gap-1.5 text-sm"
        ></top-line>

        <div class="mt-7">
            <h3 class="sr-only">Event short description</h3>
            <h4
                class="whitespace-break-spaces text-sm text-gray at1024:text-base"
            >
                {{ product.elevatorPitch?.trim() }}
            </h4>
        </div>

        <bottom-line
            v-if="product.published"
            class="mt-6 flex flex-row flex-wrap gap-4 text-sm"
            @link-to-description-click="handleDescriptionLinkClick"
        ></bottom-line>
    </div>
</template>

<script setup>
import BottomLine from 'components/EventDetail/Lede/BottomLine.vue';
import TopLine from 'components/EventDetail/Lede/TopLine';
import trim from 'lodash/trim';
import { computed, inject, toValue } from 'vue';

const product = inject('product');

const headline = computed(() => {
    const p = toValue(product);
    const type = p.isVirtual ? 'Virtual' : 'In-person';
    const region = p.locationRegion ? ` in ${p.locationRegion}` : '';
    return trim(
        `${p.hostType} ${type} ${p.primaryCategory ?? 'Event'} ${region}`
    ).replace('  ', ' ');
});

const handleDescriptionLinkClick = (clickedElement) => {
    try {
        const descriptionEl = document.getElementById(
            `${clickedElement}-details`
        );

        if (descriptionEl) {
            const promise = new Promise((resolve, reject) => {
                try {
                    descriptionEl.scrollIntoView({ behavior: 'smooth' });
                    resolve(true);
                } catch (e) {
                    reject();
                }
            });
            promise.then(() => {
                if (descriptionEl.dataset.headlessuiState !== 'open') {
                    descriptionEl.click();
                }
            });
        }
    } catch (e) {}
};
</script>

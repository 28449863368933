<template>
    <div data-testid="event-wizard-step-date">
        <main>
            <h1>Do you have a date in mind?</h1>
            <div class="content relative">
                <date-picker
                    v-model="selectedDate"
                    :autoApply="true"
                    :enableTimePicker="false"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :month-change-on-scroll="false"
                    :teleport="true"
                    :transitions="false"
                    placeholder="Select a date"
                    data-testid="event-wizard-date-picker"
                    week-start="0"
                >
                    <template #input-icon>
                        <calendar-icon
                            class="ml-3 h-5 w-5 stroke-1 text-black"
                        ></calendar-icon>
                    </template>
                </date-picker>
                <p class="mt-2 text-sm text-gray-500" v-if="!selectedDate">
                    Leave this blank if you're flexible on a date.
                </p>

                <div
                    class="mt-4 flex flex-col items-start gap-y-4"
                    v-if="selectedDate"
                >
                    <h4 class="text-base font-semibold">
                        Event start time window
                    </h4>
                    <div class="flex gap-x-8">
                        <div class="flex flex-col items-start gap-y-2 text-sm">
                            <label class="font-medium" for="earliestStartTime"
                                >Earliest start time</label
                            >
                            <time-select
                                v-model="startTime"
                                is-required
                                id="earliestStartTime"
                                class="rounded border-gray-300 py-2 pl-3 pr-10"
                            ></time-select>
                        </div>
                        <div class="flex flex-col items-start gap-y-2 text-sm">
                            <label class="font-medium" for="latestStartTime"
                                >Latest start time</label
                            >
                            <time-select
                                :key="startTime"
                                v-model="endTime"
                                is-required
                                :later-than="startTime"
                                id="latestStartTime"
                                class="rounded border-gray-300 py-2 pl-3 pr-10"
                            ></time-select>
                        </div>

                        <div
                            class="flex shrink flex-col items-start gap-y-2 text-sm"
                        >
                            <label class="font-medium" for="timeZone"
                                >Timezone</label
                            >
                            <timezone-select
                                v-model="timezone"
                                is-required
                                id="timeZone"
                                class="max-w-[230px] rounded border-gray-300 py-2 pl-3 pr-10"
                            ></timezone-select>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <modal-footer @next-click="proceed"></modal-footer>
    </div>
</template>
<script setup>
import { useEventDateFilter } from '@/composables/useEventDateFilter';
import { useEventWizard } from '@/composables/useEventWizard';
import { CalendarIcon } from '@heroicons/vue/outline';
import DatePicker from '@vuepic/vue-datepicker';
import TimeSelect from 'components/Global/Inputs/TimeSelect.vue';
import TimezoneSelect from 'components/Global/Inputs/TimezoneSelect.vue';
import ModalFooter from '../ModalFooter.vue';

const { nextStep, pushFilter, getFilter } = useEventWizard();
const {
    minDate,
    maxDate,
    selectedDate,
    startTime,
    endTime,
    timezone,
    dateFilterData,
} = useEventDateFilter(...(getFilter('event_date')?.split(',') ?? []));

const proceed = () => {
    if (dateFilterData) {
        pushFilter('event_date', dateFilterData.value);
    }
    nextStep();
};
</script>

<template>
    <ul>
        <li v-if="hasListBefore">
            <slot name="listBefore"></slot>
        </li>
        <li v-for="(item, idx) in visibleItems">
            <slot name="item" :idx="idx" v-bind="item"></slot>
        </li>
        <li v-if="hasListAfter">
            <slot name="listAfter"></slot>
        </li>
        <li v-if="canExpand" class="col-span-full">
            <slot name="expanderAction" :opened="opened">
                <a
                    @click.prevent="opened = !opened"
                    href="#"
                    class="flex flex-row items-end space-x-1 text-gray-400 underline-offset-1 hover:text-black hover:underline"
                >
                    <chevron-down-icon
                        class="mb-px w-4"
                        :class="opened ? 'rotate-180' : ''"
                    ></chevron-down-icon>
                    <span class="text-black">
                        {{ opened ? 'see less' : 'see more' }}
                    </span>
                </a>
            </slot>
        </li>
    </ul>
</template>

<script setup>
import { computed, ref, useSlots } from 'vue';

import { ChevronDownIcon } from '@heroicons/vue/outline';

const props = defineProps({
    limit: {
        type: Number,
        default: () => 4,
    },
    items: {
        type: Array,
        required: true,
    },
    opened: {
        type: Boolean,
        required: false,
        default: () => false,
    },
});

const $slots = useSlots();

const opened = ref(props.opened);

const hasListBefore = computed(() => {
    return !!$slots.listBefore;
});

const hasListAfter = computed(() => {
    return !!$slots.listAfter;
});

const limit = computed(() => {
    return props.limit - (hasListBefore.value ? 1 : 0);
});

const visibleItems = computed(() => {
    return opened.value ? props.items : props.items.slice(0, limit.value);
});

const canExpand = computed(() => {
    return props.items.length > props.limit;
});
</script>

<style scoped></style>

<template>
    <div
        v-if="!consented"
        class="fixed bottom-0 z-50 border border-gray-300 bg-gray-100 px-3 py-4 text-center text-sm at600:bottom-3 at600:right-3 at600:max-w-120 at600:rounded-lg at600:px-5"
    >
        <h3 class="text-lg font-semibold">
            <badge-check-icon
                class="-mt-0.5 inline-block w-5"
            ></badge-check-icon>
            Cookie settings
        </h3>
        <p class="mt-1">
            This website utilizes technologies such as cookies to enable
            essential site functionality, as well as for analytics and targeted
            advertising purposes.
            <a class="underline" href="/privacy" target="_blank">Learn more</a>
        </p>
        <div class="mt-3 flex justify-center space-x-2 at480:space-x-3">
            <button
                @click="acceptAll"
                class="btn-black btn-short px-2 text-sm at480:px-4"
            >
                Accept All
            </button>
            <button
                @click="acceptSelection"
                class="btn-white btn-short px-2 text-sm at480:px-4"
            >
                Accept Selection
            </button>
            <button
                @click="rejectAll"
                class="btn-gray btn-short px-2 text-sm at480:px-4"
            >
                Reject
            </button>
        </div>
        <div class="mt-3 flex justify-center space-x-3 at480:space-x-5">
            <label
                ><input
                    class="-mt-0.5 mr-1 inline-block"
                    type="checkbox"
                    value="Necessary"
                    checked
                    disabled
                />Necessary</label
            >
            <label class="cursor-pointer"
                ><input
                    ref="consentAnalytics"
                    class="-mt-0.5 mr-1 inline-block"
                    type="checkbox"
                    value="Analytics"
                    checked
                />Analytics</label
            >
            <label class="cursor-pointer"
                ><input
                    ref="consentMarketing"
                    class="-mt-0.5 mr-1 inline-block"
                    type="checkbox"
                    value="Marketing"
                    checked
                />Marketing</label
            >
        </div>
    </div>
</template>
<script setup>
import { trackGtmEvent } from '@/helpers/gtm';
import { BadgeCheckIcon } from '@heroicons/vue/outline';
import { ref } from 'vue';

const cookieName =
    window.elevent?.cookie?.consent_cookie_name ?? 'consent_mode';
const getCookie = () => {
    return window.elevent?.cookie?.get(cookieName);
};
const setCookie = (consent) => {
    window.elevent?.cookie?.set(cookieName, JSON.stringify(consent), 365);
};
const consented = ref(getCookie() !== null);
const consentAnalytics = ref(null);
const consentMarketing = ref(null);

const setConsent = (consent) => {
    const consentMode = {
        functionality_storage: consent.necessary ? 'granted' : 'denied',
        security_storage: consent.necessary ? 'granted' : 'denied',
        ad_storage: consent.marketing ? 'granted' : 'denied',
        ad_user_data: consent.marketing ? 'granted' : 'denied',
        ad_personalization: consent.marketing ? 'granted' : 'denied',
        analytics_storage: consent.analytics ? 'granted' : 'denied',
    };
    if (typeof gtag === 'function') {
        gtag('consent', 'update', consentMode);
    }
    trackGtmEvent('consent_updated');
    setCookie(consentMode);
    consented.value = true;
};

const acceptAll = () => {
    setConsent({
        necessary: true,
        analytics: true,
        marketing: true,
    });
};
const acceptSelection = () => {
    setConsent({
        necessary: true,
        analytics: consentAnalytics.value.checked,
        marketing: consentMarketing.value.checked,
    });
};
const rejectAll = () => {
    setConsent({
        necessary: false,
        analytics: false,
        marketing: false,
    });
};
</script>

<template>
    <DisclosureButton as="div" v-if="thingsYouNeed" class="group">
        <h4 class="group-hover:underline">Things you'll need</h4>
        <slot></slot>
    </DisclosureButton>
</template>

<script setup>
import { DisclosureButton } from '@headlessui/vue';
import { inject } from 'vue';

const product = inject('product');

const thingsYouNeed = product.thingsYouNeed?.trim();
</script>

<style scoped></style>

import EleventGuaranteeModal from '@/Components/Global/EleventGuaranteeModal';
import AdjustableHeadcountModal from './AdjustableHeadcountModal';
import FreeShippingModal from './FreeShippingModal';
import InstantBookModal from './InstantBookModal';

export default {
    shippingDelivery: FreeShippingModal,
    guarantee: EleventGuaranteeModal,
    instantBook: InstantBookModal,
    adjustableHeadcount: AdjustableHeadcountModal,
};

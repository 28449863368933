import { DateTime } from 'luxon';
import { computed, ref } from 'vue';

const minDate = DateTime.now().plus({ days: 1 }).toJSDate();
const maxDate = DateTime.now().plus({ days: 120 }).toJSDate();

export function useEventDateFilter(
    initialDate = null,
    selectedTimezone = null,
    eventStartTime = '07:00',
    eventEndTime = '18:00',
    dateFormat = 'yyyy-L-dd'
) {
    if (!selectedTimezone) {
        selectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    if (initialDate) {
        initialDate = DateTime.fromFormat(initialDate, dateFormat);
        if (initialDate.isValid) {
            initialDate = initialDate.toJSDate();
        } else {
            initialDate = null;
        }
    }

    const selectedDate = ref(initialDate);
    const startTime = ref(eventStartTime);
    const endTime = ref(eventEndTime);
    const timezone = ref(selectedTimezone);

    const dateFilterData = computed(() => {
        return selectedDate.value
            ? [
                  DateTime.fromJSDate(selectedDate.value).toFormat(dateFormat),
                  timezone.value,
                  startTime.value,
                  endTime.value,
              ]
            : null;
    });

    return {
        minDate,
        maxDate,
        selectedDate,
        dateFormat,
        startTime,
        endTime,
        timezone,
        dateFilterData,
    };
}

<template>
    <a
        v-if="product.hasShipments && !product.price.shipping"
        href="#"
        @click.prevent=""
    >
        <span class="flex flex-row items-center space-x-2">
            <truck-icon class="h-6 w-6"></truck-icon
            ><span class="font-medium underline underline-offset-2"
                >Free shipping</span
            >
        </span>
    </a>
</template>

<script setup>
import { TruckIcon } from '@heroicons/vue/outline';
import { inject } from 'vue';

const product = inject('product');
</script>

<template>
    <Disclosure
        v-for="(description, idx) in descriptionTypes"
        v-slot="{ open }"
        :default-open="isOpenOnRender(idx)"
    >
        <Component
            class="relative flex w-full cursor-pointer scroll-m-3 flex-row items-center border-t border-gray-300 py-4 font-bold leading-8"
            :is="descriptionElements[description]['heading']"
            :id="description + '-details'"
        >
            <chevron-down-icon
                class="absolute right-0 mr-4 h-5 w-5 text-gray-500 ui-open:rotate-180 ui-open:transform"
            ></chevron-down-icon>
        </Component>

        <Component
            :is="descriptionElements[description]['content']"
            class="copy-block rounded border border-gray-300 p-4 pb-8 !text-sm at768:p-8"
            :class="open ? 'mb-10' : 'mb-6'"
        >
        </Component>
    </Disclosure>
</template>

<script setup>
import { Disclosure } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';

const props = defineProps({
    descriptionElements: {
        type: Object,
        required: true,
    },
    initialOpen: {
        type: String,
        required: false,
        default: 'first',
        validator(value) {
            return ['first', 'all', 'none'].includes(value);
        },
    },
});

const descriptionTypes = Object.keys(props.descriptionElements);

const isOpenOnRender = (index) => {
    if (props.initialOpen === 'first' && index === 0) {
        return true;
    }
    return props.initialOpen === 'all';
};
</script>

<style scoped></style>

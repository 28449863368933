<template>
    <div v-if="isVisible">
        <span>
            This event is available for instant book for events after
            <span v-if="hideIcon" class="font-semibold">
                {{ instantBookStartAfterDate }}.
            </span></span
        >
        <div v-if="!hideIcon" class="mt-2">
            <lightning-bolt-icon
                class="mr-1 inline-block w-5 fill-inherit"
            ></lightning-bolt-icon>
            <span class="font-semibold"> {{ instantBookStartAfterDate }} </span>
        </div>
        <p class="mt-2 font-normal">
            Events before this date typically take 24 hours to confirm.
        </p>
    </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import { useStorefront } from '@/composables/useStorefront';
import { LightningBoltIcon } from '@heroicons/vue/solid';
import { DateTime } from 'luxon';

const { getStorefront } = useStorefront();
const storefront = getStorefront();

const instantBookNow = ref(null);
const timeoutId = ref(null);

const props = defineProps({
    hideIcon: {
        type: Boolean,
        required: false,
        default: false,
    },
    product: {
        type: Object,
        required: true,
    },
});

const setInstantBookNow = () => {
    instantBookNow.value = DateTime.now().startOf('day');
    timeoutId.value = setTimeout(
        setInstantBookNow,
        DateTime.now().plus({ days: 1 }).startOf('day').ts - DateTime.now().ts
    );
};

onMounted(() => {
    if (
        props.product.host?.instantBookEnabled &&
        props.product.host?.instantBookLeadTime
    ) {
        setInstantBookNow();
    }
});

onBeforeUnmount(() => {
    if (timeoutId.value) {
        clearTimeout(timeoutId.value);
    }
});

const isVisible = computed(() => {
    return (
        instantBookNow.value && storefront.isPublic && !props.product.isLeadForm
    );
});

const instantBookStartAfterDate = computed(() => {
    return instantBookNow.value
        ?.plus({
            days: props.product.host?.instantBookLeadTime,
        })
        .toFormat('MMMM d, yyyy');
});
</script>

<style scoped></style>

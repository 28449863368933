<template>
    <svg
        fill="currentColor"
        stroke="currentColor"
        style="transform: scale(0.95)"
        viewBox="138.702 3.072 17.357 21.392"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M 146.932 23.692 L 147.002 23.732 L 147.03 23.748 C 147.256 23.87 147.527 23.87 147.753 23.748 L 147.781 23.733 L 147.852 23.692 C 148.243 23.46 148.625 23.213 148.996 22.95 C 149.957 22.272 150.855 21.508 151.679 20.668 C 153.623 18.678 155.642 15.688 155.642 11.841 C 155.642 5.491 148.767 1.521 143.267 4.697 C 140.714 6.17 139.142 8.894 139.142 11.841 C 139.142 15.687 141.162 18.678 143.105 20.668 C 143.929 21.508 144.826 22.272 145.787 22.95 C 146.159 23.213 146.541 23.46 146.932 23.692 Z"
        />
    </svg>
</template>

<script setup></script>

<style scoped></style>

import capitalize from 'lodash/capitalize';

function dataLayer() {
    return window.dataLayer || [];
}

function listingCategory(listing) {
    return listing.isEvent
        ? listing.isVirtual
            ? 'virtual'
            : 'in-person'
        : 'product';
}

export function trackGtmEvent(eventName, params = {}) {
    dataLayer().push({
        event: eventName,
        ...params,
    });
}

export function trackGtmEcommerceEvent(eventName, params, extra = {}) {
    dataLayer().push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer().push({
        event: eventName,
        ecommerce: params,
        ...extra,
    });
}

export function trackViewItemEvent(product) {
    if (!product.published) {
        return;
    }
    const item = {
        item_id: product.uuid,
        item_name: product.name,
        google_business_vertical: 'custom',
    };
    if (item.isEvent) {
        item.item_category = 'event';
        item.item_category2 = product.isVirtual ? 'virtual' : 'in-person';
        item.item_category3 = product.primaryCategory;
    } else {
        item.item_category = 'product';
        item.item_category2 = product.primaryCategory;
    }
    trackGtmEcommerceEvent(
        'view_item',
        { items: [item] },
        { category: listingCategory(product) }
    );
}

export function trackItemListFilterEvent(source, extra = {}) {
    trackGtmEvent('item_list_filtered', {
        item_list_id: source,
        item_list_name: capitalize(source.replace('_', ' ')),
        ...extra,
    });
}

export function trackViewItemListEvent(source, products) {
    const items = [];
    for (let i in products) {
        items.push({
            item_id: products[i].uuid,
            item_name: products[i].name,
            index: parseInt(i),
            google_business_vertical: 'custom',
        });
    }
    trackGtmEcommerceEvent('view_item_list', {
        item_list_id: source,
        item_list_name: capitalize(source.replace('_', ' ')),
        items: items,
    });
}

export function trackAddToCartEvent(product) {
    trackGtmEcommerceEvent(
        'add_to_cart',
        {
            items: [
                {
                    item_id: product.uuid,
                    item_name: product.name,
                    google_business_vertical: 'custom',
                },
            ],
        },
        { category: listingCategory(product) }
    );
}

export default {
    computed: {
        currentPage() {
            return parseInt(this.productListMeta.page) || 1;
        },
        pageCount() {
            return parseInt(this.productListMeta.page_count);
        },
        totalResults() {
            return !this.loading
                ? parseInt(this.productListMeta.total_results)
                : false;
        },
    },
};

<template>
    <vue-final-modal
        class="flex items-center justify-center"
        content-class="relative w-full max-w-[620px] bg-white px-4 py-6 at600:rounded-lg at768:px-8 at900:w-2/3"
    >
        <modal-header @close="$emit('close')"></modal-header>

        <slot></slot>
    </vue-final-modal>
</template>
<script setup>
import ModalHeader from 'components/Global/Modal/ModalHeader';
import { VueFinalModal } from 'vue-final-modal';

const $emit = defineEmits(['close']);
</script>

<template>
    <vue-final-modal
        v-slot="{ params, close }"
        v-bind="$attrs"
        class="--modal-container"
        :content-class="'--modal-content ' + contentClass"
        @beforeClose="closeMe"
    >
        <div class="relative">
            <div class="--modal__content">
                <slot :params="params" :close="closeMe"></slot>
            </div>

            <button class="--modal__close" @click="closeMe">
                <x-icon class="w-5"></x-icon>
            </button>

            <slot name="footer"></slot>
        </div>
    </vue-final-modal>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { XIcon } from '@heroicons/vue/outline';
import { VueFinalModal } from 'vue-final-modal';

import 'vue-final-modal/style.css';

const props = defineProps({
    contentClass: {
        type: String,
        required: false,
        default: () => '',
    },
});

const $emit = defineEmits(['update:modelValue']);

const closeMe = () => {
    $emit('update:modelValue', false);
};
</script>

<style>
.--modal-container {
    @apply flex items-center justify-center overflow-y-scroll at768:overflow-y-auto at768:py-8;

    .--modal-content {
        @apply relative m-auto flex flex-col overflow-y-auto bg-white at768:min-h-fit at768:rounded-lg;
    }

    .--modal__close {
        @apply absolute right-3 top-3;
    }

    .vfm--overlay {
        @apply !fixed;
    }
}
</style>

<template>
    <DisclosurePanel v-if="product.isInPerson" id="location-details">
        <div class="flex items-start text-base font-medium at1024:space-x-2">
            <globe-icon
                class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
            ></globe-icon>

            <p v-if="product.brickInPerson.isTypeWillComeToYou">
                The host will travel to your location for this event. You can
                provide your location during checkout or coordinate with the
                host after booking.
            </p>

            <p v-else-if="product.brickInPerson.isTypeBoth">
                The host can travel to your location
                <strong class="!font-bold italic">or</strong> your guests can
                travel to the host's location. You can choose your preference
                during checkout.
            </p>

            <p v-else-if="product.brickInPerson.isTypeLocation">
                Your guests will need to travel to the host's location to attend
                the event.
            </p>
        </div>

        <div
            v-if="
                product.brickInPerson.transportation ||
                product.brickInPerson.locationRestrictions ||
                (product.brickInPerson.maximumTravelToCustomer &&
                    product.brickInPerson.locationCity)
            "
            class="copy-block !text-sm"
        >
            <p v-if="product.brickInPerson.transportation">
                {{ product.brickInPerson.transportation }}
            </p>
            <p v-if="product.brickInPerson.locationRestrictions">
                {{ product.brickInPerson.locationRestrictions }}
            </p>
            <p
                v-if="
                    product.brickInPerson.maximumTravelToCustomer &&
                    product.brickInPerson.locationCity
                "
            >
                The host will to travel up to
                {{ product.brickInPerson.maximumTravelToCustomer }} miles from
                {{ product.brickInPerson.locationCity }}.
            </p>
        </div>

        <div
            v-if="product.brickInPerson.isGeocoded"
            class="flex items-start at768:ml-8 at1024:ml-0 at1200:ml-8"
        >
            <location-marker-icon class="h-6 w-6"></location-marker-icon>
            <span class="ml-2 inline-block font-medium"
                >This location is <span class="italic">approximate</span>. The
                exact location is provided after booking.</span
            >
        </div>

        <iframe
            v-if="googleMapsEmbedUrl"
            class="h-[400px] w-full rounded-lg border border-gray-300 at768:h-[600px]"
            width="auto"
            referrerpolicy="no-referrer-when-downgrade"
            :src="googleMapsEmbedUrl"
            allowfullscreen
        >
        </iframe>
    </DisclosurePanel>
</template>

<script setup>
import { DisclosurePanel } from '@headlessui/vue';
import { GlobeIcon, LocationMarkerIcon } from '@heroicons/vue/outline';
import { inject } from 'vue';

const product = inject('product');
const googleApiKey = GOOGLE_MAPS_API_KEY;
const googleMapsEmbedUrl =
    googleApiKey && product.brickInPerson?.point
        ? `https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${product.brickInPerson?.point}`
        : false;
</script>

<style scoped></style>

<template>
    <button
        @click="$emit('update:modelValue', !modelValue)"
        class="flex items-center gap-x-2"
    >
        <template v-if="!modelValue">
            <map-solid v-if="useSolidIcons" class="w-5"></map-solid>
            <map-outline v-else class="w-5"></map-outline>
            <span>Show map</span>
        </template>
        <template v-else>
            <list-bullet-solid
                v-if="useSolidIcons"
                class="w-5"
            ></list-bullet-solid>
            <list-bullet-outline v-else class="w-5"></list-bullet-outline>
            <span>Show list</span>
        </template>
    </button>
</template>

<script setup>
import {
    ListBulletIcon as ListBulletOutline,
    MapIcon as MapOutline,
} from '@heroicons-v2/vue/24/outline';
import {
    ListBulletIcon as ListBulletSolid,
    MapIcon as MapSolid,
} from '@heroicons-v2/vue/24/solid';

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: false,
        default: false,
    },
    useSolidIcons: {
        type: Boolean,
        required: false,
        default: true,
    },
});

const $emit = defineEmits(['update:modelValue']);
</script>

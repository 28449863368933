<template>
    <span
        v-if="isColor()"
        class="mr-3 inline-block h-5 w-5 rounded"
        :style="{ backgroundColor: swatch }"
    ></span>
    <img
        v-else-if="isImage()"
        :src="swatch"
        :alt="alt"
        class="mr-3 inline-block h-5 w-5 rounded"
    />
</template>
<script setup>
const props = defineProps({
    swatch: {
        type: String,
        required: true,
    },
    alt: {
        type: String,
        required: false,
        default: '',
    },
});

function isColor() {
    return props.swatch.indexOf('#') === 0;
}
function isImage() {
    return props.swatch.indexOf('://') !== -1;
}
</script>

<template>
    <DisclosurePanel v-if="thingsYouNeed">
        <div
            class="mb-2 flex items-start text-base font-medium at1024:space-x-2"
        >
            <clipboard-document-check-icon
                class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
            ></clipboard-document-check-icon>
            <span
                >Each guest will need to supply the items below to participate
                fully in this event.</span
            >
        </div>

        <description-copy-block
            :description="thingsYouNeed"
        ></description-copy-block>
    </DisclosurePanel>
</template>

<script setup>
import { DisclosurePanel } from '@headlessui/vue';
import { ClipboardDocumentCheckIcon } from '@heroicons-v2/vue/24/outline';
import DescriptionCopyBlock from 'components/ListingDetail/DescriptionCopyBlock.vue';
import { computed, inject } from 'vue';

const product = inject('product');

const thingsYouNeed = computed(() => product.thingsYouNeed?.trim());
</script>

<style scoped></style>

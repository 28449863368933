<template>
    <div class="tl-list flex flex-row">
        <div v-for="type in listTypes">
            <Component
                @click.prevent="$emit('linkToDescriptionClick', type)"
                :is="PageLedeTopElements[type]"
                :data-testid="`pdp-topLine-${type}`"
            ></Component>
        </div>
    </div>
</template>

<script setup>
import PageLedeTopElements from './TopLine/index';

const $emit = defineEmits(['linkToDescriptionClick']);

const listTypes = Object.keys(PageLedeTopElements);
</script>

<style scoped>
.tl-list > *:empty {
    display: none;
}
.tl-list > *:not(:first-child):before {
    content: '\2022';
    @apply pr-1.5;
}
</style>

<template>
    <DisclosureButton as="div" v-if="product.isVirtual" class="group">
        <h4 class="group-hover:underline">
            How to join<template v-if="product.isSelfHosted">
                a self-hosted virtual event</template
            >
        </h4>
        <slot></slot>
    </DisclosureButton>
</template>

<script setup>
import { DisclosureButton } from '@headlessui/vue';
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped></style>

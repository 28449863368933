import AgendaContent from './Agenda/DescriptionContent';
import AgendaHeading from './Agenda/DescriptionHeading';
import HowItWorksContent from './HowItWorks/DescriptionContent';
import HowItWorksHeading from './HowItWorks/DescriptionHeading';
import InPersonEventContent from './InPersonEvent/DescriptionContent';
import InPersonEventHeading from './InPersonEvent/DescriptionHeading';
import KitContentsContent from './KitContents/DescriptionContent';
import KitContentsHeading from './KitContents/DescriptionHeading';
import ShippingDeliveryContent from './ShippingDelivery/DescriptionContent';
import ShippingDeliveryHeading from './ShippingDelivery/DescriptionHeading';
import ThingsYouNeedContent from './ThingsYouNeed/DescriptionContent';
import ThingsYouNeedHeading from './ThingsYouNeed/DescriptionHeading';
import VirtualEventContent from './VirtualEvent/DescriptionContent';
import VirtualEventHeading from './VirtualEvent/DescriptionHeading';

export default {
    howItWorks: {
        content: HowItWorksContent,
        heading: HowItWorksHeading,
    },
    agenda: {
        content: AgendaContent,
        heading: AgendaHeading,
    },
    kitContents: {
        content: KitContentsContent,
        heading: KitContentsHeading,
    },
    shippingDelivery: {
        content: ShippingDeliveryContent,
        heading: ShippingDeliveryHeading,
    },
    thingsYouNeed: {
        content: ThingsYouNeedContent,
        heading: ThingsYouNeedHeading,
    },
    virtualEvent: {
        content: VirtualEventContent,
        heading: VirtualEventHeading,
    },
    inPersonEvent: {
        content: InPersonEventContent,
        heading: InPersonEventHeading,
    },
};

<template>
    <DisclosurePanel :unmount="false">
        <div class="flex items-center text-base font-medium at1024:space-x-2">
            <information-circle-icon
                class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
            ></information-circle-icon>
            <span>About this item</span>
        </div>

        <div class="whitespace-break-spaces">{{ product.description }}</div>
    </DisclosurePanel>
</template>

<script setup>
import { DisclosurePanel } from '@headlessui/vue';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped></style>

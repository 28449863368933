<template>
    <div :id="scrollToId">
        <placeholder-grid
            v-if="loading"
            :item-ct="8"
            class="tiles-grid"
        ></placeholder-grid>
        <template v-else>
            <product-grid
                :products="products"
                :currency="productListMeta.currency"
                class="tiles-grid"
                data-testid="simple-product-grid"
            ></product-grid>
            <simple-pagination
                class="mt-8 flex justify-center at1024:mt-12"
                :current-page="currentPage"
                :pages="pageCount"
                @page-next-requested="loadProducts(currentPage + 1)"
                @page-prev-requested="loadProducts(currentPage - 1)"
            ></simple-pagination>
        </template>
    </div>
</template>
<script setup>
import axios from 'axios';
import PlaceholderGrid from 'components/ProductList/PlaceholderGrid.vue';
import ProductGrid from 'components/ProductList/ProductGrid.vue';
import SimplePagination from 'components/SimplePagination.vue';
import uniqueId from 'lodash/uniqueId';
import { computed, onMounted, ref, shallowRef } from 'vue';
import VueScrollTo from 'vue-scrollto';

const props = defineProps({
    resultsEndpointUrl: {
        type: String,
        required: true,
    },
    pageSize: {
        type: Number,
        default: 8,
    },
});
const scrollToId = uniqueId('spl_');
const loading = ref(false);
const products = shallowRef([]);
const productListMeta = ref({});

const currentPage = computed(() => parseInt(productListMeta.value.page) || 1);
const pageCount = computed(() => parseInt(productListMeta.value.page_count));

const loadProducts = async (page) => {
    page = page || currentPage.value;
    loading.value = true;
    try {
        if (products.value.length > 0) {
            VueScrollTo.scrollTo('#' + scrollToId, 250, {
                force: false,
                offset: -30,
            });
        }
        const response = await axios.get(props.resultsEndpointUrl, {
            params: {
                page: page,
                limit: props.pageSize,
            },
        });
        products.value = response.data.products || [];
        productListMeta.value = response.data.meta || {};
    } catch (e) {
        products.value = [];
        productListMeta.value = {
            page_count: 1,
            total_results: 0,
        };
        throw e;
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    loadProducts();
});
</script>

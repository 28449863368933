<template>
    <span v-if="product.isVirtual && !product.isSelfHosted">
        {{ product.host.defaultVideoConferencePlatformLabel
        }}<template
            v-if="product.host.supportedVideoConferencePlatforms.length"
        >
            and
            <a
                href="#"
                @click.prevent=""
                class="font-medium underline underline-offset-2 hover:text-orange"
            >
                {{ product.host.supportedVideoConferencePlatforms.length }}
                others</a
            ></template
        >
    </span>
</template>

<script setup>
import { inject } from 'vue';

const product = inject('product');
</script>

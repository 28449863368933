<template>
    <div class="cms-faq-container my-8 bg-sand at1024:my-20">
        <div
            class="mx-auto px-4 py-10 at480:px-6 at768:py-24 at1024:max-w-thin at1024:px-8"
        >
            <h2
                class="mb-4 text-center text-2xl font-bold leading-[1.1] text-blue at768:text-3xl at1024:text-5xl"
            >
                {{ title }}
            </h2>
            <ul>
                <li
                    v-for="(item, index) in content"
                    class="waves-line-black py-6 at768:py-9"
                >
                    <div class="flex justify-between">
                        <h3
                            class="text-lg font-bold text-blue hover:cursor-pointer at768:text-xl at1024:text-2xl"
                            @click="indexUpdater(index)"
                        >
                            {{ item.question }}
                        </h3>
                        <button
                            @click="indexUpdater(index)"
                            class="shrink-0 duration-200"
                            :class="
                                openIndex.includes(index) ? 'rotate-45' : ''
                            "
                        >
                            <img
                                :src="assetUrl('/static/img/plus-purple.svg')"
                                height="22"
                                width="22"
                                alt="Toggle FAQ answer"
                                aria-hidden="true"
                            />
                            <span class="sr-only">Toggle answer</span>
                        </button>
                    </div>
                    <div
                        class="faq-answer copy-block transition-height linear space-y-4 overflow-hidden text-base duration-100 at768:text-lg at1024:text-xl"
                        :class="
                            openIndex.includes(index)
                                ? 'mt-6 h-full opacity-100'
                                : 'h-0 opacity-0'
                        "
                        v-html="item.answer"
                    />
                </li>
            </ul>
        </div>
        <component
            :is="'script'"
            v-html="jsonLd"
            type="application/ld+json"
        ></component>
    </div>
</template>

<script>
import Faqs from '../../mixins/faqs';

export default {
    name: 'CmsFaqs',
    mixins: [Faqs],
};
</script>

<style>
.faq-answer {
    p {
        @apply !mt-4;
    }
    a {
        @apply text-black underline hover:no-underline;
    }
}
</style>

<template>
    <span class="price">
        <span v-if="hasRangePricing"> Starting at </span>
        <span v-else-if="hasDiscount" class="strike-price">
            {{ formatCurrency(originalBasePrice) }}
        </span>
        <span> {{ formatCurrency(basePrice) }} / item </span>
        <span v-if="showShipping" class="shipping-price font-normal">
            &plus;
            {{ shippingPrice ? formatCurrency(shippingPrice) : 'free' }}
            shipping
        </span>
    </span>
</template>

<script setup>
import { formatCurrency } from '@/helpers/priceFormatters';
import { computed, toValue } from 'vue';

const props = defineProps({
    price: {
        type: Object,
        required: true,
    },
    showShipping: {
        type: Boolean,
        default: false,
    },
});

const hasDiscount = computed(() => props.price.discountPercentage);
const hasRangePricing = computed(() => props.price.hasRangePricing);
const discountPercentage = computed(
    () => (props.price.discountPercentage || 0) / 100
);
const basePrice = computed(() => props.price.base || 0);
const originalBasePrice = computed(() =>
    Math.round(toValue(basePrice) / (1 - toValue(discountPercentage)))
);
const shippingPrice = computed(() => props.price.shipping || 0);
</script>

<template>
    <div>
        <clearable-filter-header
            class="mb-6 flex items-center space-x-2"
            filter-key="durations"
            @filter-cleared="selectedDurations = []"
            >How long is your event?</clearable-filter-header
        >

        <ul class="grid grid-cols-1 gap-y-3 text-sm at720:grid-cols-2">
            <li
                v-for="(duration, idx) in durations"
                class="flex flex-row items-center space-x-4"
            >
                <input
                    :id="`modal-filter-duration-${idx}`"
                    @change="handleSelection(duration.value, $event)"
                    type="checkbox"
                    :value="duration.value"
                    class="rounded border-gray-300"
                    :checked="
                        findIndex(
                            selectedDurations,
                            (v) => v === duration.value
                        ) > -1
                    "
                />
                <label :for="`modal-filter-duration-${idx}`">{{
                    duration.label
                }}</label>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import ClearableFilterHeader from 'components/ProductList/ProductFilter/ModalFilters/ClearableFilterHeader.vue';
import findIndex from 'lodash/findIndex';
import { computed, ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: null,
    },
});

const { appliedFilters, filterOptions, queueFilter } = useCollectionFilters();
const durations = filterOptions.durations;
const selectedDurations = ref(props.current ? [...props.current] : []);

const isFilterApplied = computed(() => appliedFilters.value.durations);
const clearFilter = () => {
    queueFilter('durations', null);
    selectedDurations.value = [];
};

const handleSelection = (duration, event) => {
    if (event.target.checked) {
        selectedDurations.value.push(duration);
        selectedDurations.value.sort();
    } else {
        try {
            let index = findIndex(
                selectedDurations.value,
                (v) => v === duration
            );
            if (index !== -1) {
                selectedDurations.value.splice(index, 1);
            }
        } catch (e) {}
    }
    queueFilter('durations', selectedDurations.value);
};
</script>

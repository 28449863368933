import { useUserInfo } from '@/composables/useUserInfo';
import { ServiceRouter } from '@/service-router';
import axios from 'axios';
import { ref, shallowRef } from 'vue';

const serviceRouter = new ServiceRouter();
const wishlist = shallowRef(null);
const fetching = ref(false);
const { isAuthenticated, xsrfToken } = useUserInfo(() => {
    fetchWishlist();
});

export function useWishlist() {
    return {
        wishlist,
        isInWishlist,
        addToWishlist,
        removeFromWishlist,
    };
}

// fetch a paginated wishlist for the session user
async function fetchWishlist(ttlInMinutes = 10) {
    if (wishlist.value !== null || fetching.value) {
        return;
    }
    fetching.value = true;
    wishlist.value = JSON.parse(localStorage.getItem('wishlist'));
    const lastFetch = parseInt(localStorage.getItem('wishlist-last-fetch'));
    try {
        if (
            !wishlist.value ||
            !lastFetch ||
            Date.now() - lastFetch > 1000 * 60 * ttlInMinutes
        ) {
            const response = await axios.get(
                serviceRouter.eventManagerUrl('webapi/wishlist'),
                {
                    withCredentials: true,
                    validateStatus: function (status) {
                        // don't error for 401
                        return (
                            (status >= 200 && status < 300) || status === 401
                        );
                    },
                }
            );
            wishlist.value =
                response.status === 200
                    ? {
                          ...response.data.data,
                          total_count: response.data.meta?.total ?? 0,
                      }
                    : {};
            localStorage.setItem('wishlist', JSON.stringify(wishlist.value));
            localStorage.setItem('wishlist-last-fetch', Date.now().toString());
        }
    } finally {
        fetching.value = false;
    }
}

// check if a listing is in the wishlist. since we only store the latest 10 items locally, a request
// is made if the item is not found.
async function isInWishlist(listingUuid) {
    if (!isAuthenticated.value) {
        return false;
    }
    let found =
        wishlist.value?.items.some(
            (item) => item.listing_uuid === listingUuid
        ) ?? false;
    if (!found) {
        const response = await axios.get(
            serviceRouter.eventManagerUrl('webapi/wishlist/' + listingUuid),
            {
                withCredentials: true,
            }
        );
        if (response.status === 200) {
            found = true;
        }
    }
    return found;
}

async function addToWishlist(listingUuid) {
    if (!isAuthenticated.value) {
        return false;
    }
    await axios.post(
        serviceRouter.eventManagerUrl('webapi/wishlist/' + listingUuid),
        {},
        {
            withCredentials: true,
            headers: {
                'X-CSRF-TOKEN': xsrfToken.value,
            },
        }
    );
    localStorage.removeItem('wishlist');
    return true;
}

async function removeFromWishlist(listingUuid) {
    await axios.delete(
        serviceRouter.eventManagerUrl('webapi/wishlist/' + listingUuid),
        {
            withCredentials: true,
            headers: {
                'X-CSRF-TOKEN': xsrfToken.value,
            },
        }
    );
    localStorage.removeItem('wishlist');
    return true;
}

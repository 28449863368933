<template>
    <div>
        <div class="mb-6 flex flex-col gap-y-1">
            <h3 class="text-lg font-semibold">Live hosting</h3>
            <span class="text-sm font-normal text-gray-400"
                >A self-hosted event will require you to start the event and is
                typically less expensive.</span
            >
        </div>

        <div class="flex flex-col gap-y-4 text-sm">
            <span class="isolate inline-flex rounded-md">
                <button
                    @click="toggleFilter(true)"
                    type="button"
                    class="relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
                    :class="
                        filterValue === true
                            ? 'cursor-default bg-black text-white ring-black'
                            : 'bg-white ring-gray-300 hover:text-blue'
                    "
                    data-testid="modal-filters-hosted-live-button"
                >
                    Live-hosted
                </button>
                <button
                    @click="toggleFilter(null)"
                    type="button"
                    class="relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
                    :class="
                        filterValue === null
                            ? 'cursor-default bg-black text-white ring-black'
                            : 'bg-white ring-gray-300 hover:text-blue'
                    "
                    data-testid="modal-filters-hosted-both-button"
                >
                    Both
                </button>
                <button
                    @click="toggleFilter(false)"
                    type="button"
                    class="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
                    :class="
                        filterValue === false
                            ? 'cursor-default bg-black text-white ring-black'
                            : 'bg-white ring-gray-300 hover:text-blue'
                    "
                    data-testid="modal-filters-hosted-self-button"
                >
                    Self-hosted
                </button>
            </span>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { queueFilter } = useCollectionFilters();
const filterValue = ref(props.current);

const toggleFilter = (value) => {
    filterValue.value = value;
    queueFilter(
        'is_hosted',
        filterValue.value === null ? null : Boolean(filterValue.value)
    );
};
</script>

<script>
import kebabCase from 'lodash/kebabCase';
import MicroModal from 'micromodal';

import { useActiveNavGroup } from '@/composables/useActiveNavGroup';
import { useStorefront } from '@/composables/useStorefront';
import OndemandProductList from 'components/OndemandProductList.vue';
import { ModalsContainer } from 'vue-final-modal';
import EventDetail from './Components/EventDetail';
import EventWizardButton from './Components/EventWizardButton';
import FilterableProductList from './Components/FilterableProductList';
import CmsFaqs from './Components/Global/CmsFaqs';
import CookieConsent from './Components/Global/CookieConsent';
import EleventGuaranteeModalLink from './Components/Global/EleventGuaranteeModalLink';
import EventFaqs from './Components/Global/EventFaqs';
import GradientHero from './Components/Global/GradientHero';
import GlobalHeader from './Components/Global/Header';
import SearchBox from './Components/Global/Search/SearchBox.vue';
import HomeHero from './Components/Home/Hero';
import HostDetail from './Components/HostDetail';
import ProductDetail from './Components/ProductDetail';
import ProductTile from './Components/ProductList/ProductTile';
import CollectionSearchResults from './Components/Search/CollectionResults';
import ProductSearchResults from './Components/Search/ProductResults';

export default {
    props: {
        storefrontJsonStr: {
            type: String,
            required: false,
            default:
                '{"isMainStorefront":true,"isPublic":true,"subdomain":"www"}',
            validator(value) {
                try {
                    JSON.parse(value);
                    return true;
                } catch (e) {
                    return false;
                }
            },
        },
        activeNavGroup: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const { setStorefront } = useStorefront();
        setStorefront(JSON.parse(props.storefrontJsonStr));

        const { setActiveNavGroup } = useActiveNavGroup();
        setActiveNavGroup(props.activeNavGroup);
    },
    mounted() {
        Object.keys(this.$props).forEach((prop) => {
            this.$el.parentElement.removeAttribute('data-' + kebabCase(prop));
        });
    },
    created() {
        MicroModal.init();
    },
    components: {
        CmsFaqs,
        CollectionSearchResults,
        CookieConsent,
        EleventGuaranteeModalLink,
        EventDetail,
        EventFaqs,
        EventWizardButton,
        FilterableProductList,
        GlobalHeader,
        GradientHero,
        HomeHero,
        HostDetail,
        ModalsContainer,
        OndemandProductList,
        ProductDetail,
        ProductSearchResults,
        ProductTile,
        SearchBox,
    },
};
</script>

<template>
    <div>
        <div class="mb-2 flex flex-col gap-y-1">
            <h3 class="text-base font-semibold">Choose a date</h3>
            <span class="text-xs font-normal text-gray-400"
                >Optionally filter for the exact date of your event.</span
            >
        </div>

        <div class="relative flex flex-col gap-y-6 text-sm">
            <date-picker
                :autoApply="true"
                :enableTimePicker="false"
                :min-date="minDate"
                :max-date="maxDate"
                :month-change-on-scroll="false"
                placeholder="Select a date"
                :transitions="false"
                v-model="selectedDate"
                class="max-w-[330px]"
                week-start="0"
            >
                <template #input-icon>
                    <calendar-icon
                        class="ml-3 h-5 w-5 stroke-1 text-black"
                    ></calendar-icon>
                </template>
            </date-picker>
        </div>

        <div class="mt-4 flex flex-col items-start gap-y-2 text-sm">
            <h4 class="text-sm font-semibold">Event start time window</h4>
            <div class="flex gap-x-4">
                <div class="flex flex-col items-start gap-y-2 text-xs">
                    <label class="font-medium" for="earliestStartTime"
                        >Earliest start time</label
                    >
                    <time-select
                        v-model="startTime"
                        is-required
                        id="earliestStartTime"
                        class="rounded border-gray-300 py-2 pl-3 pr-10 text-sm"
                    ></time-select>
                </div>
                <div class="flex flex-col items-start gap-y-2 text-xs">
                    <label class="font-medium" for="latestStartTime"
                        >Latest start time</label
                    >
                    <time-select
                        :key="startTime"
                        v-model="endTime"
                        is-required
                        :later-than="startTime"
                        id="latestStartTime"
                        class="rounded border-gray-300 py-2 pl-3 pr-10 text-sm"
                    ></time-select>
                </div>
            </div>

            <div
                class="flex max-w-full shrink flex-col items-start gap-y-2 text-xs"
            >
                <label class="font-medium" for="timeZone">Timezone</label>
                <timezone-select
                    v-model="timezone"
                    is-required
                    id="timeZone"
                    class="max-w-full rounded border-gray-300 py-2 pl-3 pr-10 text-sm"
                ></timezone-select>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { useEventDateFilter } from '@/composables/useEventDateFilter';
import { CalendarIcon } from '@heroicons/vue/outline';
import DatePicker from '@vuepic/vue-datepicker';
import TimeSelect from 'components/Global/Inputs/TimeSelect.vue';
import TimezoneSelect from 'components/Global/Inputs/TimezoneSelect.vue';
import { watch } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => [],
    },
});
const { applyFilter } = useCollectionFilters();
const {
    minDate,
    maxDate,
    selectedDate,
    startTime,
    endTime,
    timezone,
    dateFilterData,
} = useEventDateFilter(...props.current);

watch(dateFilterData, (newValue) => {
    if (newValue) {
        applyFilter('event_date', newValue);
    }
});
</script>

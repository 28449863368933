<template>
    <div
        v-if="hasMarkup(description)"
        v-html="description"
        v-bind="$attrs"
        class="copy-block !text-sm"
    ></div>
    <div v-else v-bind="$attrs" class="whitespace-break-spaces">
        {{ decodedDescription }}
    </div>
</template>
<script setup>
import { hasMarkup } from '@/helpers/productAttributes';
import { computed, toValue } from 'vue';

const props = defineProps({
    description: {
        type: String,
        required: true,
    },
});

// Decodes HTML entities in the description, e.g. &amp; -> &
const decodedDescription = computed(
    () =>
        new DOMParser().parseFromString(toValue(props.description), 'text/html')
            .documentElement.textContent
);
</script>

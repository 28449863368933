<template>
    <div>
        <h3 class="mb-8 text-base font-medium text-black at1024:text-lg">
            {{ title }}
        </h3>
        <ul>
            <li v-for="(item, index) in content" class="mt-4">
                <div
                    @click="indexUpdater(index)"
                    class="flex justify-between bg-gray-200 px-5 py-4 hover:cursor-pointer"
                >
                    <h4 class="text-sm font-semibold at1024:text-base">
                        {{ item.question }}
                    </h4>
                    <button
                        class="shrink-0 duration-200"
                        :class="openIndex.includes(index) ? 'rotate-45' : ''"
                    >
                        <plus-icon
                            class="mr-1 w-5 text-black at1024:w-6"
                        ></plus-icon>
                        <span class="sr-only">Toggle answer</span>
                    </button>
                </div>
                <div
                    class="copy-block transition-height linear space-y-4 overflow-hidden px-5 text-gray-500 duration-100"
                    :class="
                        openIndex.includes(index)
                            ? 'h-full py-4 opacity-100'
                            : 'h-0 opacity-0'
                    "
                    v-html="item.answer"
                />
            </li>
        </ul>
    </div>
    <component
        :is="'script'"
        v-html="jsonLd"
        type="application/ld+json"
    ></component>
</template>

<script>
import { PlusIcon } from '@heroicons/vue/outline';
import Faqs from '../../mixins/faqs';

export default {
    name: 'EventFaqs',
    components: {
        PlusIcon,
    },
    mixins: [Faqs],
};
</script>

<template>
    <div class="px-4 at1024:mx-auto at1024:max-w-wide at1024:px-8">
        <div
            class="bg-site-gradient flex flex-col rounded-xl p-0 at768:flex-row at768:justify-between at768:p-10 at1024:p-16"
        >
            <figure class="h-28 at768:order-2 at768:h-auto at768:w-[50%]">
                <slot name="image"></slot>
            </figure>
            <div
                class="flex flex-col items-start justify-center px-4 py-6 at768:order-1 at768:w-[45%] at768:px-0 at768:py-0"
            >
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>
<script setup></script>

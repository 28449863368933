<template>
    <div>
        <div
            class="flex flex-col space-y-4 px-4 at720:px-6 at1024:px-0 at1200:space-y-7"
        >
            <bread-crumbs
                v-if="
                    product.breadcrumbs.length > 0 &&
                    storefront.isMainStorefront
                "
                :crumbs="product.breadcrumbs"
                class="text-xs text-gray-500"
            ></bread-crumbs>

            <page-heading></page-heading>

            <image-gallery
                :images="product.images"
                :video="product.videoUrl"
            ></image-gallery>
        </div>
        <div
            class="mx-auto mt-4 grid grid-cols-1 at1024:mt-8 at1024:max-w-standard at1024:grid-cols-5 at1024:grid-rows-[auto_auto] at1024:gap-x-16"
        >
            <div class="order-1 px-4 at720:px-6 at1024:hidden at1024:px-0">
                <page-lede class="mb-7"></page-lede>
            </div>
            <div
                class="order-3 mt-8 px-4 at720:px-6 at1024:order-2 at1024:col-span-3 at1024:mt-0 at1024:px-0"
            >
                <page-lede class="mb-7 hidden at1024:block"></page-lede>

                <listing-descriptions
                    v-if="product.published"
                    :description-elements="ListingDescriptionElements"
                ></listing-descriptions>

                <product-reviews
                    :show-product-name="product.variants?.variants?.length > 0"
                    class="border-t border-gray-300 pb-8 pt-8"
                ></product-reviews>

                <product-faqs
                    v-if="product.faqs.length > 0"
                    :faqs="product.faqs"
                    class="border-t border-gray-300 pt-8"
                >
                    <template #title>
                        <h3
                            class="mb-4 text-base font-medium text-black at1024:text-xl"
                        >
                            Frequently asked questions
                        </h3>
                    </template>
                </product-faqs>
            </div>

            <!-- right col -->
            <div
                class="order-2 mt-4 at1024:relative at1024:order-3 at1024:col-span-2 at1024:row-span-2 at1024:mt-0"
            >
                <div class="space-y-8 at1024:sticky at1024:top-8">
                    <quote-lead-form
                        v-if="product.isLeadForm"
                        class="bg-gray-200 px-4 py-8 at720:px-8 at1024:bg-white"
                    ></quote-lead-form>
                    <booking-form
                        v-else
                        @variant-selected="variantSelected"
                        class="bg-gray-200 px-4 py-8 at720:px-8 at1024:bg-white"
                    ></booking-form>

                    <template v-if="product.published">
                        <ask-question
                            class="mx-auto max-w-[450px] px-4 at720:px-8 at1024:rounded-lg at1024:border at1024:border-gray-300 at1024:p-4 at1200:p-8"
                        ></ask-question>

                        <instant-book-widget
                            :product="product"
                            class="ib-widget items-start rounded-md bg-linen fill-orange px-4 py-6 text-center text-sm font-semibold"
                        ></instant-book-widget>

                        <ondemand-cta
                            v-if="
                                storefront.isMainStorefront &&
                                product.flags.showOndemandCta === true
                            "
                        ></ondemand-cta>

                        <multi-location-info-box
                            v-if="!product.isInPerson && product.hasShipments"
                            :intl-shipping-available="
                                product.host.shipping.international
                            "
                            class="flex items-start space-x-4 rounded bg-blue-300 px-6 py-4"
                            >Choose whether you want all of your guest's
                            shipments going to a single location or to each
                            guest's address. Don't worry, you can see all of the
                            shipments right in the
                            portal.</multi-location-info-box
                        >
                    </template>
                </div>
            </div>
        </div>

        <related-products
            :host-products="product.hostProducts"
            :related-products="product.relatedProducts"
        ></related-products>

        <slot name="pdp-after"></slot>
    </div>
</template>

<script setup>
import { useStorefront } from '@/composables/useStorefront';
import { trackViewItemEvent } from '@/helpers/gtm';
import OndemandCta from 'components/EventDetail/OndemandCta.vue';
import RelatedProducts from 'components/ListingDetail/RelatedProducts.vue';
import { onMounted, provide, reactive, ref } from 'vue';
import BookingForm from './EventDetail/BookingForm';
import ListingDescriptionElements from './EventDetail/Details/Descriptions';
import InstantBookWidget from './EventDetail/InstantBookWidget';
import ImageGallery from './EventDetail/Media/ImageGallery';
import PageLede from './EventDetail/PageLede';
import ProductFaqs from './EventDetail/ProductFaqs';
import ProductReviews from './EventDetail/ProductReviews';
import AskQuestion from './ListingDetail/AskQuestion';
import BreadCrumbs from './ListingDetail/BreadCrumbs';
import ListingDescriptions from './ListingDetail/ListingDescriptions';
import MultiLocationInfoBox from './ListingDetail/MultiLocationInfoBox';
import PageHeading from './ListingDetail/PageHeading';
import QuoteLeadForm from './ListingDetail/QuoteLeadForm';

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
});

const product = reactive(props.product);

provide('product', product);
const { getStorefront } = useStorefront();
const storefront = getStorefront();

onMounted(() => {
    trackViewItemEvent(product);
});

const selectedVariant = ref({});
function variantSelected(variant) {
    selectedVariant.value = variant;
    for (const property in variant.product) {
        product[property] = variant.product[property];
    }
}
</script>

<style scoped>
:deep(.ib-widget p) {
    @apply text-xs;
}
</style>

import Budget from './Budget.vue';
import Category from './Category.vue';
import EventDate from './EventDate.vue';
import Results from './Results.vue';

export default {
    budget: Budget,
    date: EventDate,
    category: Category,
    results: Results,
};

<template>
    <footer>
        <button
            v-if="canGoBack"
            @click="previousStep"
            class="btn-white"
            data-testid="event-wizard-back-button"
        >
            <chevron-left-icon></chevron-left-icon><span>Back</span>
        </button>
        <button
            v-else
            @click="closeAll"
            class="btn-white"
            data-testid="event-wizard-close-button"
        >
            <x-icon></x-icon><span>Close</span>
        </button>
        <button
            v-if="canContinue"
            @click="$emit('next-click')"
            class="btn-black"
            data-testid="event-wizard-continue-button"
        >
            <span>Continue</span>
            <chevron-right-icon></chevron-right-icon>
        </button>
    </footer>
</template>
<script setup>
import { useEventWizard } from '@/composables/useEventWizard';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    XIcon,
} from '@heroicons/vue/outline';
import { useVfm } from 'vue-final-modal';

const { closeAll } = useVfm();
const { canGoBack, canContinue, previousStep } = useEventWizard();
const emit = defineEmits(['next-click']);
</script>

import {
    DesktopComputerIcon,
    GiftIcon,
    OfficeBuildingIcon,
} from '@heroicons/vue/outline';
import map from 'lodash/map';
import { ref } from 'vue';

const activeNavGroup = ref(null);

export function useActiveNavGroup() {
    const setActiveNavGroup = (navGroup) => {
        if (navGroup) {
            activeNavGroup.value = navGroup;
        }
    };

    const searchTypeOptions = [
        {
            label: 'Virtual events',
            value: 'virtual',
            icon: DesktopComputerIcon,
        }, //default
        {
            label: 'In-person events',
            value: 'in-person',
            icon: OfficeBuildingIcon,
        },
        { label: 'Gifts', value: 'gifts', icon: GiftIcon },
    ];

    const validForSearch = map(searchTypeOptions, 'value');
    const defaultForSearch = validForSearch[0];

    const currentSearchType = validForSearch.includes(activeNavGroup.value)
        ? activeNavGroup.value
        : defaultForSearch;

    return {
        activeNavGroup,
        currentSearchType,
        searchTypeOptions,
        setActiveNavGroup,
    };
}

<template>
    <div>
        <form :action="searchPath" method="GET" class="mt-1.5 flex">
            <search-box-filters
                v-model="searchFilter"
                class="relative rounded-l-md border border-r-0 border-gray-400 text-xxs at480:text-xs"
            ></search-box-filters>

            <input type="text" class="hidden" name="t" :value="searchFilter" />

            <input
                v-model="searchQuery"
                ref="queryInput"
                id="search"
                name="q"
                class="block w-full border border-x-0 border-gray-400 focus:border-gray-400"
                :placeholder="placeholder"
                required
                minlength="3"
                type="text"
            />

            <button
                type="submit"
                class="rounded-r-md bg-black px-3 text-xs text-white at600:px-6 at600:text-sm"
            >
                <span class="hidden at600:inline">Search</span>
                <search-icon
                    class="h-5 w-5 text-white at600:hidden"
                ></search-icon>
            </button>
        </form>
    </div>
</template>

<script setup>
import { useActiveNavGroup } from '@/composables/useActiveNavGroup';
import { SearchIcon } from '@heroicons/vue/solid';
import SearchBoxFilters from 'components/Global/Search/SearchBoxFilters.vue';
import { onMounted, ref } from 'vue';

const props = defineProps({
    searchPath: {
        type: String,
        required: false,
        default: () => '/search',
    },
    focusesInput: {
        type: Boolean,
        required: false,
        default: () => false,
    },
});
const { currentSearchType } = useActiveNavGroup();
const placeholder =
    currentSearchType === 'gifts' ? 'Chocolate' : 'Cooking classes';
const params = new URLSearchParams(window.location.search);
const searchQuery = ref(params.get('q') || '');
const searchFilter = ref(params.get('t') || currentSearchType);

const queryInput = ref();
onMounted(() => {
    if (props.focusesInput) {
        focusInput();
    }
});

const focusInput = () => {
    queryInput.value.focus();
};
</script>

<template>
    <DisclosureButton v-if="product.contents" as="div" class="group">
        <h4 class="group-hover:underline">What's included in the package</h4>
        <slot></slot>
    </DisclosureButton>
</template>

<script setup>
import { DisclosureButton } from '@headlessui/vue';
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped></style>

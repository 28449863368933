import EventDuration from './EventDuration';
import GroupSize from './GroupSize';
import KitIncluded from './KitIncluded';
import LeadTime from './LeadTime';
import SupportedConferencing from './SupportedConferencing';

export default {
    groupSize: GroupSize,
    duration: EventDuration,
    kitContents: KitIncluded,
    virtualEvent: SupportedConferencing,
    leadTime: LeadTime,
};

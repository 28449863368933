<template>
    <Listbox as="div" class="relative" data-testid="sort-options-menu">
        <ListboxButton
            class="listbox-button flex w-full items-center justify-between gap-x-1.5 rounded border border-gray-300 bg-white px-3 py-2 text-sm font-medium"
            data-testid="sort-options-button"
        >
            <span>Sort by: {{ currentLabel }}</span>
            <chevron-down-icon class="mb-px w-4"></chevron-down-icon>
        </ListboxButton>
        <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <ListboxOptions
                class="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-gray-300 focus:outline-none"
            >
                <ListboxOption
                    v-for="(label, option) in sortOptions"
                    :key="option"
                    class="listbox-option relative select-none ui-active:bg-gray-200"
                >
                    <a
                        @click="setSortOption(option)"
                        class="block cursor-pointer p-3"
                        :class="{
                            'font-semibold underline underline-offset-2':
                                currentSort === option,
                        }"
                        :title="label"
                        :data-testid="`sort-option-${option}`"
                        >{{ label }}</a
                    >
                </ListboxOption>
            </ListboxOptions>
        </transition>
    </Listbox>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';
import { computed, ref } from 'vue';

const props = defineProps({
    default: {
        type: String,
        required: false,
        default: () => '',
    },
    sortOptions: {
        type: Object,
        required: true,
    },
});
const { applyFilter } = useCollectionFilters();
const currentSort = ref(
    props.default ? props.default : Object.keys(props.sortOptions)[0]
);
const currentLabel = computed(() => props.sortOptions[currentSort.value]);

const setSortOption = (sortOption) => {
    currentSort.value = sortOption;
    applyFilter('sort', currentSort.value);
};
</script>

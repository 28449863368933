import AdjustableHeadcount from './AdjustableHeadcount';
import EleventGuarantee from './EleventGuarantee';
import FreeShipping from './FreeShipping';
import InPersonEvent from './InPersonEvent';
import InstantBook from './InstantBook';

export default {
    shippingDelivery: FreeShipping,
    instantBook: InstantBook,
    inPersonEvent: InPersonEvent,
    guarantee: EleventGuarantee,
    adjustableHeadcount: AdjustableHeadcount,
};

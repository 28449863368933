<template>
    <div>
        <simple-product-list
            :results-endpoint-url="resultsEndpointUrl"
            :page-size="20"
            @click="gridClicked"
        ></simple-product-list>
    </div>
</template>
<script setup>
import InfoModal from 'components/Global/InfoModal.vue';
import SimpleProductList from 'components/SimpleProductList.vue';
import { defineComponent } from 'vue';
import { useModal, useModalSlot } from 'vue-final-modal';

const props = defineProps({
    resultsEndpointUrl: {
        type: String,
        required: true,
    },
});

const OndemandInfoModal = defineComponent({
    template: `
        <header>
            <h2 class="mx-auto font-semibold text-xl text-center">
                Want to learn more?
            </h2>
        </header>
        <main class="mt-4 text-center">
            <p class="text-gray-500">Join a demo and play any of these games live to see for yourself.</p>
            <p class="mt-6">
                <a href="https://meetings.hubspot.com/jason1036/ondemand-team-building-demo" class="inline-block btn-blue btn-pill px-8">
                    Request a demo
                </a>
            </p>
        </main>
    `,
});

const modalInstance = useModal({
    component: InfoModal,
    attrs: {
        onClose: () => modalInstance.close(),
    },
    slots: {
        default: useModalSlot({
            component: OndemandInfoModal,
        }),
    },
});

// open modal when the grid is clicked and prevent navigation to the PDP
const gridClicked = (event) => {
    let target = event.target;
    do {
        if (target.classList.contains('product-tile')) {
            event.preventDefault();
            modalInstance.open();
            break;
        }
    } while ((target = target.parentElement));
};
</script>

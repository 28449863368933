<template>
    <div class="relative">
        <div class="mb-2 flex flex-row items-center space-x-1.5">
            <h3 class="text-base font-semibold">Supported languages</h3>
            <a
                v-if="current"
                href="#"
                @click.prevent="clearSelections"
                class="text-xs"
                >clear</a
            >
        </div>

        <expandable-list
            class="grid grid-cols-1 gap-y-2 text-sm at720:grid-cols-2"
            :items="languages"
            :limit="8"
        >
            <template #listBefore>
                <div class="flex flex-row items-center space-x-1.5">
                    <input
                        type="checkbox"
                        value="en"
                        class="rounded border-gray-300 !bg-gray-300 accent-gray-300"
                        :checked="selectedLanguages.length === 0"
                        readonly
                        disabled
                    />
                    <span>English</span>
                </div>
            </template>
            <template #item="{ idx, label, value }">
                <div class="flex flex-row items-center space-x-1.5">
                    <input
                        :id="`grid-filter-language-${idx}`"
                        @change="handleSelection(value, $event)"
                        type="checkbox"
                        :value="value"
                        class="rounded border-gray-300"
                        :checked="selectedLanguages.indexOf(value) > -1"
                    />
                    <label :for="`grid-filter-language-${idx}`">{{
                        label
                    }}</label>
                </div>
            </template>
        </expandable-list>

        <button
            v-if="showApplyButton"
            @click="applyFilters"
            type="button"
            class="absolute -bottom-2.5 right-0 rounded border border-gray-300 bg-white px-2 py-2 text-sm hover:bg-gray-100/90"
        >
            Apply
        </button>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';

import { useCollectionFilters } from '@/composables/useCollectionFilters';
import ExpandableList from 'components/Global/ExpandableList.vue';
import isEqual from 'lodash/isEqual';

const { filterOptions } = useCollectionFilters();
const languages = filterOptions.languages;

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { applyFilter } = useCollectionFilters();
const selectedLanguages = ref(props.current ? [...props.current] : []);

const handleSelection = (language, event) => {
    if (event.target.checked) {
        selectedLanguages.value.push(language);
        selectedLanguages.value.sort();
    } else {
        try {
            let index = selectedLanguages.value.indexOf(language);
            if (index !== -1) {
                selectedLanguages.value.splice(index, 1);
            }
        } catch (e) {}
    }
};

const clearSelections = () => {
    selectedLanguages.value = [];
    applyFilters();
};

const applyFilters = () => {
    applyFilter('lang', selectedLanguages.value);
};

const showApplyButton = computed(() => {
    return !isEqual(selectedLanguages.value, props.current || []);
});
</script>

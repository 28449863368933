import { usePushHistoryState } from '@/composables/usePushHistoryState';
import axios from 'axios';
import { computed, ref, shallowRef } from 'vue';

export function useSearch(searchQuery, searchType = 'virtual') {
    const collections = shallowRef([]);
    const products = shallowRef([]);
    const collectionsListMeta = ref({});
    const productListMeta = ref({});
    const searching = ref(false);

    const currentPage = computed(() => {
        return productListMeta.value.page ?? 1;
    });

    const pageCount = computed(() => {
        return parseInt(productListMeta.value.page_count);
    });

    const totalResults = computed(() => {
        return !searching.value
            ? parseInt(productListMeta.value.total_results ?? 0)
            : false;
    });

    const pushToHistory = (searchQuery, page) => {
        let pushToHistory = {
            q: searchQuery,
        };
        if (page > 1) {
            pushToHistory['page'] = page;
        } else {
            delete pushToHistory['page'];
        }
        usePushHistoryState(pushToHistory);
    };

    const loadCollectionResults = async (page = 1) => {
        page = page ?? 1;
        const params = new URLSearchParams();
        params.append('q', searchQuery);
        params.append('t', searchType);
        params.append('page', page);
        try {
            if (searchType === 'in-person') {
                throw new TypeError('Search type not supported');
            }
            const response = await axios.get('/webapi/search/collections', {
                params,
            });
            collections.value = response.data.collections || [];
            collectionsListMeta.value = response.data.meta || {};
        } catch (e) {
            collections.value = [];
            collectionsListMeta.value = {
                page_count: 1,
                total_results: 0,
            };
            if (!(e instanceof TypeError)) {
                throw e;
            }
        }
    };

    const loadResults = async (page, pushesHistory = true) => {
        page = page ?? currentPage.value;
        const params = new URLSearchParams();
        params.append('q', searchQuery);
        params.append('t', searchType);
        params.append('page', page);

        searching.value = true;

        try {
            const response = await axios.get('/webapi/search/products', {
                params,
            });
            if (pushesHistory) {
                pushToHistory(searchQuery, page);
            }
            products.value = response.data.products || [];
            productListMeta.value = response.data.meta || {};
        } catch (e) {
            products.value = [];
            productListMeta.value = {
                page_count: 1,
                total_results: 0,
            };
            throw e;
        } finally {
            searching.value = false;
        }
    };

    return {
        searching,
        products,
        productListMeta,
        currentPage,
        pageCount,
        totalResults,
        loadResults,
        collections,
        collectionsListMeta,
        loadCollectionResults,
    };
}

<template>
    <DisclosurePanel v-if="contents" :unmount="false">
        <div class="flex items-center text-base font-medium at1024:space-x-2">
            <gift-icon
                class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
            ></gift-icon>
            <span>Your recipient will be receiving the following items</span>
        </div>

        <description-copy-block
            :description="contents"
        ></description-copy-block>
    </DisclosurePanel>
</template>

<script setup>
import { DisclosurePanel } from '@headlessui/vue';
import { GiftIcon } from '@heroicons-v2/vue/24/outline';
import DescriptionCopyBlock from 'components/ListingDetail/DescriptionCopyBlock.vue';
import { computed, inject } from 'vue';

const product = inject('product');
const contents = computed(() => product.contents);
</script>

<style scoped></style>

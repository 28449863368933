<template>
    <div>
        <div class="flex flex-col gap-y-4">
            <div>
                <h3 class="text-lg font-semibold">Choose a date</h3>
                <span class="text-sm font-normal text-gray-400"
                    >Optionally filter for the exact date of your event.</span
                >
                <div class="relative mt-4 flex flex-col gap-y-6 text-sm">
                    <date-picker
                        :autoApply="true"
                        :enableTimePicker="false"
                        :min-date="minDate"
                        :max-date="maxDate"
                        :month-change-on-scroll="false"
                        placeholder="Select a date"
                        :transitions="false"
                        v-model="selectedDate"
                        class="max-w-[330px]"
                        week-start="0"
                    >
                        <template #input-icon>
                            <calendar-icon
                                class="ml-3 h-5 w-5 stroke-1 text-black"
                            ></calendar-icon>
                        </template>
                    </date-picker>
                </div>
            </div>

            <div class="flex flex-col items-start gap-y-4">
                <h4 class="text-base font-semibold">Event start time window</h4>
                <div class="flex gap-x-8">
                    <div class="flex flex-col items-start gap-y-2 text-sm">
                        <label class="font-medium" for="earliestStartTime"
                            >Earliest start time</label
                        >
                        <time-select
                            v-model="startTime"
                            is-required
                            id="earliestStartTime"
                            class="rounded border-gray-300 py-2 pl-3 pr-10"
                        ></time-select>
                    </div>
                    <div class="flex flex-col items-start gap-y-2 text-sm">
                        <label class="font-medium" for="latestStartTime"
                            >Latest start time</label
                        >
                        <time-select
                            :key="startTime"
                            v-model="endTime"
                            is-required
                            :later-than="startTime"
                            id="latestStartTime"
                            class="rounded border-gray-300 py-2 pl-3 pr-10"
                        ></time-select>
                    </div>

                    <div
                        class="flex shrink flex-col items-start gap-y-2 text-sm"
                    >
                        <label class="font-medium" for="timeZone"
                            >Timezone</label
                        >
                        <timezone-select
                            v-model="timezone"
                            is-required
                            id="timeZone"
                            class="max-w-[230px] rounded border-gray-300 py-2 pl-3 pr-10"
                        ></timezone-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { useEventDateFilter } from '@/composables/useEventDateFilter';
import { CalendarIcon } from '@heroicons/vue/outline';
import DatePicker from '@vuepic/vue-datepicker';
import TimeSelect from 'components/Global/Inputs/TimeSelect.vue';
import TimezoneSelect from 'components/Global/Inputs/TimezoneSelect.vue';
import { watch } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => [],
    },
});

const { queueFilter } = useCollectionFilters();
const {
    minDate,
    maxDate,
    selectedDate,
    startTime,
    endTime,
    timezone,
    dateFilterData,
} = useEventDateFilter(...props.current);
watch(dateFilterData, (newValue) => {
    if (newValue) {
        queueFilter('event_date', newValue);
    }
});
</script>

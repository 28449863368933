<template>
    <div class="relative">
        <div class="mb-2 flex flex-row items-center space-x-1.5">
            <h3 class="text-base font-semibold">Supported video platforms</h3>
            <a
                v-if="current"
                href="#"
                @click.prevent="clearSelections"
                class="text-xs"
                >clear</a
            >
        </div>

        <expandable-list
            class="flex flex-col space-y-2 text-sm"
            :items="platforms"
        >
            <template #item="{ idx, label, value }">
                <div class="flex items-center space-x-1.5">
                    <input
                        :id="`grid-filter-platform-${idx}`"
                        @change="handleSelection(value, $event)"
                        type="checkbox"
                        :value="value"
                        class="rounded border-gray-300"
                        :checked="selectedPlatforms.indexOf(value) > -1"
                    />
                    <label :for="`grid-filter-platform-${idx}`">{{
                        label
                    }}</label>
                </div>
            </template>
        </expandable-list>

        <button
            v-if="showApplyButton"
            @click="applyFilters"
            type="button"
            class="absolute bottom-0 right-0 rounded border border-gray-300 bg-white px-2 py-2 text-sm hover:bg-gray-100/90"
        >
            Apply
        </button>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import ExpandableList from 'components/Global/ExpandableList.vue';
import isEqual from 'lodash/isEqual';
import { computed, ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { applyFilter } = useCollectionFilters();
const { filterOptions } = useCollectionFilters();
const platforms = filterOptions.videoPlatforms;
const selectedPlatforms = ref(props.current ? [...props.current] : []);

const handleSelection = (platform, event) => {
    if (event.target.checked) {
        selectedPlatforms.value.push(platform);
        selectedPlatforms.value.sort();
    } else {
        try {
            let index = selectedPlatforms.value.indexOf(platform);
            if (index !== -1) {
                selectedPlatforms.value.splice(index, 1);
            }
        } catch (e) {}
    }
};

const clearSelections = () => {
    selectedPlatforms.value = [];
    applyFilters();
};

const applyFilters = () => {
    applyFilter('video_platforms', selectedPlatforms.value);
};

const showApplyButton = computed(() => {
    return !isEqual(selectedPlatforms.value, props.current || []);
});
</script>

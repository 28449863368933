import VueScrollTo from 'vue-scrollto';
export default function (isLocked, mobileOnly = true, scrollToTop = true) {
    if (scrollToTop) {
        VueScrollTo.scrollTo('body', 250, {
            force: true,
        });
    }

    const classes = [
        'overflow-hidden',
        ...(mobileOnly ? ['at768:overflow-visible'] : []),
    ];

    classes.forEach((className) => {
        if (isLocked) {
            document.body.classList.remove(className);
        } else {
            document.body.classList.add(className);
        }
    });
}

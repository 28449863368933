<template>
    <div>
        <slot name="title"></slot>

        <ul>
            <li v-for="(item, index) in faqs" class="mt-4">
                <div
                    @click="indexUpdater(index)"
                    class="flex justify-between bg-gray-200 px-4 py-4 hover:cursor-pointer"
                >
                    <h4 class="text-sm font-semibold at1024:text-base">
                        {{ item.question }}
                    </h4>
                    <button
                        class="shrink-0 duration-200"
                        :class="openIndex.includes(index) ? 'rotate-45' : ''"
                    >
                        <plus-icon
                            class="w-5 text-black at1024:-mr-0.5 at1024:w-6"
                        ></plus-icon>
                        <span class="sr-only">Toggle answer</span>
                    </button>
                </div>
                <div
                    class="copy-block transition-height linear space-y-4 overflow-hidden px-4 text-gray-500 duration-100"
                    :class="
                        openIndex.includes(index)
                            ? 'h-full py-4 opacity-100'
                            : 'h-0 opacity-0'
                    "
                    v-html="item.answer"
                />
            </li>
        </ul>

        <component
            :is="'script'"
            v-html="jsonLd"
            type="application/ld+json"
        ></component>
    </div>
</template>

<script setup>
import { useFaqs } from '@/composables/useFaqs';
import { PlusIcon } from '@heroicons-v2/vue/24/outline';

const props = defineProps({
    faqs: {
        type: Array,
        required: true,
    },
});

const { openIndex, indexUpdater, jsonLd } = useFaqs(props.faqs);
</script>

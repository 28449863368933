<template>
    <div>
        <div class="mb-6 flex flex-col gap-y-1">
            <h3 class="text-lg font-semibold">Event location</h3>
            <span class="text-sm font-normal text-gray-400"
                >Looking for a host to come to your location or for your group
                to go to the host's venue?</span
            >
        </div>

        <div class="flex flex-col gap-y-4 text-sm">
            <span class="isolate inline-flex rounded-md">
                <button
                    @click="toggleFilter('physical location')"
                    type="button"
                    class="relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
                    :class="
                        filterValue === 'physical location'
                            ? 'cursor-default bg-black text-white ring-black'
                            : 'bg-white ring-gray-300 hover:text-blue'
                    "
                    data-testid="modal-filter-physical-type-physical"
                >
                    Host's venue
                </button>
                <!--
                This doesn't allow the user to distinguish between "both" and null, which is the default/unfiltered state.
                That was deemed acceptable with ENG-1498 because when searching from the customer's standpoint, "both"
                is unfiltered -- they want to see all events, regardless of whether it's the host's location or theirs.
                -->
                <button
                    @click="toggleFilter(null)"
                    type="button"
                    class="relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
                    :class="
                        filterValue === 'both' || filterValue === null
                            ? 'cursor-default bg-black text-white ring-black'
                            : 'bg-white ring-gray-300 hover:text-blue'
                    "
                    data-testid="modal-filter-physical-type-both"
                >
                    Both
                </button>
                <button
                    @click="toggleFilter('will come to you')"
                    type="button"
                    class="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
                    :class="
                        filterValue === 'will come to you'
                            ? 'cursor-default bg-black text-white ring-black'
                            : 'bg-white ring-gray-300 hover:text-blue'
                    "
                    data-testid="modal-filter-physical-type-come_to_you"
                >
                    Your location
                </button>
            </span>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { queueFilter } = useCollectionFilters();
const filterValue = ref(props.current);

const toggleFilter = (value) => {
    filterValue.value = value;
    queueFilter('physical_type', filterValue.value);
};
</script>

import { assetUrl } from '@/cdn';

export default {
    data() {
        return {
            openIndex: [],
        };
    },
    props: {
        content: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: () => 'FAQs',
        },
    },
    computed: {
        jsonLd() {
            if (!this.content.length) {
                return '';
            }
            let data = {
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
                mainEntity: [],
            };
            for (const key in this.content) {
                let item = this.content[key];
                data.mainEntity.push({
                    '@type': 'Question',
                    name: item.question,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: item.answer, // according to the spec, this can contain markup
                    },
                });
            }
            return JSON.stringify(data);
        },
    },
    methods: {
        indexUpdater(index) {
            if (this.openIndex.includes(index)) {
                const key = this.openIndex.indexOf(index);
                if (key > -1) {
                    this.openIndex.splice(key, 1);
                }
            } else {
                this.openIndex.push(index);
            }
        },
        assetUrl: assetUrl,
    },
};

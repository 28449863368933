<template>
    <section v-if="collections.length">
        <grid-header
            :total-results="false"
            :title="gridTitle[searchType]()"
        ></grid-header>
        <div
            class="flex flex-row gap-4 overflow-x-auto pb-1 at768:gap-6 at1200:justify-start"
        >
            <a
                v-for="collection in collections"
                class="group flex w-24 shrink-0 grow-0 flex-col items-center space-y-2 text-center capitalize"
                :href="collection.url"
                :title="collection.title"
            >
                <img
                    :alt="collection.title"
                    :src="collection.thumbnail_url"
                    class="h-[100px] w-[100px] overflow-hidden rounded-full object-cover group-hover:drop-shadow-lg"
                />
                <h5 class="text-sm font-medium">{{ collection.title }}</h5>
            </a>
        </div>
    </section>
</template>

<script setup>
import { useSearch } from '@/composables/useSearch';
import GridHeader from 'components/ProductList/GridHeader';
import { onBeforeMount } from 'vue';

const props = defineProps({
    searchQuery: {
        type: String,
        required: true,
    },
    searchType: {
        type: String,
        required: false,
        default: 'virtual',
    },
});

const gridTitle = {
    virtual: () => 'Virtual event collections',
    'in-person': () => 'In-person event collections',
    gifts: () => 'Gift collections',
};

const { collections, loadCollectionResults } = useSearch(
    props.searchQuery,
    props.searchType
);

onBeforeMount(() => {
    loadCollectionResults();
});
</script>

<style scoped></style>

require('./bootstrap');
require('./hubspot');

import VueSplide from '@splidejs/vue-splide';
import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';
import App from './App.vue';

const $root = document.querySelector('#app');
const app = createApp(App, {
    ...$root.dataset,
});

app.config.globalProperties.document = window.document;
app.config.errorHandler = (err, vm, info) => {
    if (window.Rollbar) {
        window.Rollbar.error(err, { vueComponent: vm, info });
    }
    if (ENV === 'prod') {
        console.error(err);
    } else {
        throw err;
    }
};

app.directive('inline', (element) => {
    element.replaceWith(...element.children);
});

app.use(createVfm()).use(VueSplide).use(VueCookies).mount('#app');

import '../css/app.pcss';

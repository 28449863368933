<template>
    <div
        class="relative overflow-hidden"
        :class="{ '!max-h-none': state, 'max-h-[350px]': showReadMore }"
        ref="contentElement"
    >
        <slot></slot>

        <div
            v-if="showReadMore"
            class="flex w-full items-end"
            :class="{
                'absolute bottom-0 left-2 h-3/4 bg-gradient-to-b from-transparent to-white to-70% p-5':
                    !state,
            }"
        >
            <a
                class="bg-transparent underline-offset-2"
                href="#"
                @click.prevent="state = !state"
                >{{ state ? collapseLabel : expandLabel }}</a
            >
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
    expandLabel: {
        type: String,
        required: false,
        default: 'Read more',
    },
    collapseLabel: {
        type: String,
        required: false,
        default: 'Read less',
    },
    defaultOpen: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const state = ref(props.defaultOpen);

const contentElement = ref(null);
const showReadMore = ref(true);

onMounted(() => {
    if (contentElement.value.clientHeight < 300) {
        showReadMore.value = false;
    }
});
</script>

<style scoped></style>

<template>
    <div>
        <div class="mb-2 flex flex-col space-y-1">
            <h3 class="text-base font-semibold">Live hosting</h3>
            <span class="text-sm font-normal text-gray-400"
                >A self-hosted event will require you to start the event and is
                typically less expensive.</span
            >
        </div>

        <div class="flex flex-col space-y-3 text-sm">
            <div class="flex flex-row space-x-1.5">
                <input
                    @change="toggleFilter"
                    type="radio"
                    name="is_hosted"
                    id="grid-filter-hosted-both"
                    value=""
                    :checked="current === null"
                />
                <label for="grid-filter-hosted-both">All Events</label>
            </div>
            <div class="flex flex-row space-x-1.5">
                <input
                    @change="toggleFilter"
                    type="radio"
                    name="is_hosted"
                    id="grid-filter-hosted-live"
                    value="true"
                    :checked="current === true"
                />
                <label for="grid-filter-hosted-live">Live-hosted</label>
            </div>
            <div class="flex flex-row space-x-1.5">
                <input
                    @change="toggleFilter"
                    type="radio"
                    name="is_hosted"
                    id="grid-filter-hosted-self"
                    value="false"
                    :checked="current === false"
                />
                <label for="grid-filter-hosted-self">Self-hosted</label>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { applyFilter } = useCollectionFilters();
const filterValue = ref(props.current);
const toggleFilter = (event) => {
    filterValue.value = event.target.value;
    applyFilter(
        'is_hosted',
        filterValue.value === '' ? null : filterValue.value === 'true'
    );
};
</script>

import { trackGtmEvent } from '@/helpers/gtm';
import axios from 'axios';
import clone from 'lodash/clone';
import { ref } from 'vue';

export function useHubspotForm(hubspotFormId) {
    const hubspotPortalId = '8643081';

    const action = ref('');
    const hasErrors = ref(false);
    const submitted = ref(false);

    if (!hubspotFormId) {
        hasErrors.value = true;
        throw new Error('Hubspot Form ID is required');
    }

    const getHutk = () => {
        if (
            document.cookie
                .split(';')
                .some((item) => item.trim().startsWith('hubspotutk='))
        ) {
            return document.cookie
                .split('; ')
                .find((row) => row.startsWith('hubspotutk='))
                .split('=')[1];
        }
    };

    const getFormattedFields = (fields) => {
        let data = [];
        for (let key in fields) {
            data.push({
                name: key,
                value: fields[key],
            });
        }
        return data;
    };

    const initForm = (formAction) => {
        action.value = formAction;
        trackGtmEvent('eventTracking', {
            category: 'Form',
            action: formAction,
            label: 'Process initiated',
        });
    };

    const submitToHubspot = (form) => {
        let url = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`;
        let data = clone(form);
        data.fields = getFormattedFields(form.fields);
        let hubspotCookie = getHutk();
        if (hubspotCookie) {
            data.context.hutk = hubspotCookie;
        }

        return axios
            .post(url, data)
            .then((response) => {
                submitted.value = true;
                trackGtmEvent('eventTracking', {
                    category: 'Form',
                    action: action.value,
                    label: 'Form submitted',
                });
            })
            .catch((error) => {
                hasErrors.value = true;
            });
    };

    return { initForm, submitToHubspot, submitted, hasErrors };
}

<template>
    <nav class="bg-gray-200 p-5 at768:rounded-t-lg" aria-label="Progress">
        <ol role="list" class="relative mx-auto flex max-w-[500px] flex-row">
            <li
                v-for="(step, index) in steps"
                :key="step.name"
                class="group relative flex-1 shrink basis-0"
            >
                <template v-if="index < stepIndex">
                    <div
                        class="absolute left-1/2 top-2 z-0 h-0.5 w-full flex-1 bg-gray-300 group-last:hidden"
                        aria-hidden="true"
                    ></div>
                    <a
                        @click="gotoStep(step.name)"
                        class="relative z-10 cursor-pointer"
                    >
                        <span
                            class="mx-auto flex size-4 items-center justify-center rounded-full bg-gray-500 group-hover:bg-blue"
                        >
                            <check-icon
                                class="size-3 text-white"
                                aria-hidden="true"
                            ></check-icon>
                        </span>
                        <div class="mt-2 text-center">
                            <span
                                class="block text-sm text-gray-500 group-hover:text-blue"
                            >
                                {{ step.name }}
                            </span>
                        </div>
                    </a>
                </template>
                <template v-else-if="index === stepIndex">
                    <div
                        class="absolute left-1/2 top-2 z-0 h-0.5 w-full flex-1 bg-gray-300 group-last:hidden"
                        aria-hidden="true"
                    ></div>
                    <span
                        class="relative z-10 mx-auto flex size-4 items-center justify-center rounded-full border-[5px] border-blue bg-white"
                    ></span>
                    <div class="mt-2 text-center" aria-current="step">
                        <span class="block text-sm font-semibold">
                            {{ step.name }}
                        </span>
                    </div>
                </template>
                <template v-else>
                    <div
                        class="absolute left-1/2 top-2 z-0 h-0.5 w-full flex-1 bg-gray-300 group-last:hidden"
                        aria-hidden="true"
                    ></div>
                    <span
                        class="relative z-10 mx-auto flex h-4 w-4 items-center justify-center rounded-full border-[3px] border-gray-500 bg-white"
                    ></span>
                    <div class="mt-2 text-center">
                        <span class="block text-sm text-gray-800">
                            {{ step.name }}
                        </span>
                    </div>
                </template>
            </li>
        </ol>
    </nav>
</template>
<script setup>
import { useEventWizard } from '@/composables/useEventWizard';
import { CheckIcon } from '@heroicons/vue/outline';

const { steps, stepIndex, gotoStep } = useEventWizard();
</script>

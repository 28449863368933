<template>
    <div>
        <h3 class="mb-2 text-base font-semibold">International shipping</h3>

        <div class="flex flex-row items-start space-x-2">
            <input
                id="grid-filter-international-shipping"
                type="checkbox"
                @change="hasFilter = !hasFilter"
                class="mt-1 rounded border-gray-300"
                :checked="hasFilter"
            />
            <label
                for="grid-filter-international-shipping"
                @click="hasFilter = !hasFilter"
                class="text-sm"
                >Show only items that ship internationally</label
            >
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref, watch } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { applyFilter } = useCollectionFilters();
const hasFilter = ref(!!props.current);

watch(hasFilter, (newValue) => {
    applyFilter('international_shipping', newValue || null);
});
</script>

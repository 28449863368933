<template>
    <DisclosureButton as="div" v-if="product.agenda" class="group">
        <h4 class="group-hover:underline">Agenda / Run of show</h4>
        <slot></slot>
    </DisclosureButton>
</template>

<script setup>
import { inject } from 'vue';
const product = inject('product');

import { DisclosureButton } from '@headlessui/vue';
</script>

<style scoped></style>

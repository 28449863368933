<template>
    <a v-if="!product.price.isFree" href="#" @click.prevent="">
        <span class="flex flex-row items-center space-x-2">
            <badge-check-icon class="h-6 w-6"></badge-check-icon
            ><span class="font-medium underline underline-offset-2"
                >Elevent guarantee</span
            >
        </span>
    </a>
</template>

<script setup>
import { BadgeCheckIcon } from '@heroicons/vue/outline';
import { inject } from 'vue';

const product = inject('product');
</script>

import { ref } from 'vue';

export function useFaqs(faqs = []) {
    const openIndex = ref([]);

    let jsonLdData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [],
    };

    for (const key in faqs) {
        let item = faqs[key];
        jsonLdData.mainEntity.push({
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: item.answer, // according to the spec, this can contain markup
            },
        });
    }

    const jsonLd = faqs.length > 0 ? JSON.stringify(jsonLdData) : '';

    const indexUpdater = (index) => {
        if (openIndex.value.includes(index)) {
            const key = openIndex.value.indexOf(index);
            if (key > -1) {
                openIndex.value.splice(key, 1);
            }
        } else {
            openIndex.value.push(index);
        }
    };

    return { openIndex, indexUpdater, jsonLd };
}

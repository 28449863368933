<template>
    <DisclosureButton as="div" v-if="product.isInPerson" class="group">
        <h4 class="group-hover:underline">
            {{
                product.brickInPerson?.isTypeWillComeToYou
                    ? 'Travel policy'
                    : 'Location details'
            }}
        </h4>
        <slot></slot>
    </DisclosureButton>
</template>

<script setup>
import { DisclosureButton } from '@headlessui/vue';
import { inject } from 'vue';

const product = inject('product');
</script>

<style scoped></style>

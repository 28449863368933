<template>
    <Splide
        :options="carouselOptions"
        :extensions="extensions"
        aria-label="Image carousel"
    >
        <SplideSlide v-if="youtubeId" :data-splide-youtube="youtubeId">
            <img
                :src="`https://i3.ytimg.com/vi/${youtubeId}/sddefault.jpg`"
                :srcset="
                    `https://i3.ytimg.com/vi/${youtubeId}/maxresdefault.jpg` +
                    ' 2x'
                "
                class="m-auto"
                alt="YouTube video still"
            />
        </SplideSlide>
        <SplideSlide v-for="image in images" v-html="image" />
    </Splide>
</template>

<script setup>
import { Video } from '@splidejs/splide-extension-video';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

const props = defineProps({
    images: {
        type: Array,
        required: true,
    },
    start: {
        type: Number,
        required: false,
        default: () => 0,
    },
    video: {
        type: String,
        required: false,
        default: () => '',
    },
});

const carouselOptions = {
    type: 'fade',
    speed: 2000,
    autoplay: false,
    isNavigation: false,
    arrows: !!props.images.length, // don't show arrows if there's only one slide (only w/ a video)
    interval: 3000,
    rewind: true,
    keyboard: true,
    start: props.start + (!!props.video && props.start > 0 ? 1 : 0),
    video: {
        autoplay: true,
        mute: true,
    },
};

const extensions = !!props.video ? { Video } : {};

const youtubeId =
    props.video?.substring(props.video?.lastIndexOf('/') + 1) || false;
</script>

<template>
    <div>
        <div class="mb-6 flex flex-col gap-y-1">
            <h3 class="text-lg font-semibold">Group size</h3>
            <span class="text-sm font-normal text-gray-400"
                >Approximately how many guests are you planning for?</span
            >
        </div>

        <div class="relative flex flex-col gap-y-6 text-sm">
            <input
                v-model="groupSize"
                type="number"
                name="group_size"
                min="1"
                id="modal-filter-budget-group_size"
                class="block w-full max-w-[330px] rounded-md border-gray-300 py-3 text-base font-normal at768:text-sm"
                placeholder="Add your group size"
            />
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { ref, watch } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});
const { queueFilter } = useCollectionFilters();
const groupSize = ref(props.current);

watch(groupSize, (newValue) => {
    if (Number.isInteger(newValue) && newValue > 0) {
        queueFilter('group_size', newValue);
    } else if (newValue === '') {
        queueFilter('group_size', null);
    }
});
</script>

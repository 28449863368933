<template>
    <button
        v-show="isVisible"
        @click.prevent="toggleInWishlist"
        :disabled="loading"
        class="rounded border border-gray-300 px-3 py-1 text-sm font-medium hover:border-black focus:border-gray-300"
        :title="
            addedToWishlist
                ? 'This item is in your wishlist. Click to remove.'
                : ''
        "
        data-testid="wishlist-button"
    >
        <span class="flex flex-row items-center">
            <loading-spinner
                v-show="loading"
                width="20px"
                padding="3px"
            ></loading-spinner>
            <heart-icon-solid
                v-if="addedToWishlist"
                v-show="!loading"
                class="size-5 text-red-500"
            ></heart-icon-solid>
            <heart-icon-outline
                v-else
                v-show="!loading"
                class="size-5"
            ></heart-icon-outline>
            <span v-if="!addedToWishlist" v-show="!loading" class="ml-2"
                >Save to wishlist</span
            >
        </span>
    </button>
</template>
<script setup>
import { useUserInfo } from '@/composables/useUserInfo';
import { useWishlist } from '@/composables/useWishlist';
import { ServiceRouter } from '@/service-router';
import { HeartIcon as HeartIconOutline } from '@heroicons/vue/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/vue/solid';
import LoadingSpinner from 'components/Global/LoadingSpinner.vue';
import { inject, ref } from 'vue';

const product = inject('product');
const serviceRouter = new ServiceRouter();
const { wishlist, isInWishlist, addToWishlist, removeFromWishlist } =
    useWishlist();

const isVisible = ref(false);
const loading = ref(false);
const addedToWishlist = ref(false);

useUserInfo(
    async () => {
        if (await isInWishlist(product.uuid)) {
            addedToWishlist.value = true;
        }
        isVisible.value = true;
    },
    () => {
        isVisible.value = true;
    }
);

async function toggleInWishlist() {
    loading.value = true;
    try {
        if (addedToWishlist.value) {
            await removeFromWishlist(product.uuid);
            addedToWishlist.value = false;
        } else {
            const result = await addToWishlist(product.uuid);
            if (result) {
                addedToWishlist.value = true;
            } else {
                window.location.href = serviceRouter.eventManagerUrl(
                    'wishlist/add/' + product.uuid,
                    { return: btoa(window.location.href) }
                );
            }
        }
    } finally {
        loading.value = false;
    }
}
</script>

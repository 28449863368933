<template>
    <div data-testid="event-wizard-step-category">
        <main>
            <h1>What types of events are you interested in?</h1>
            <h3>
                Check those that apply or
                <a
                    @click.prevent.stop="selectAll"
                    class="cursor-pointer underline hover:no-underline"
                    >select all</a
                >.
            </h3>
            <ul class="content space-y-3">
                <li
                    v-for="category in categories"
                    :key="category.slug"
                    @click.prevent.stop="categoryChange(category.slug, $event)"
                    class="category-option flex cursor-pointer flex-row items-center justify-between rounded-md border border-gray-300 p-6"
                >
                    <span class="flex flex-col space-y-0.5">
                        <span class="font-semibold">{{ category.name }}</span>
                        <span
                            v-if="category.description"
                            class="text-sm text-gray-500"
                            >{{ category.description }}</span
                        >
                    </span>
                    <check-circle-icon
                        v-if="selectedCategories.indexOf(category.slug) > -1"
                        class="size-6"
                    ></check-circle-icon>
                    <span
                        v-else
                        class="inline-block size-5 flex-none rounded-full border border-gray-300"
                    ></span>
                </li>
            </ul>
        </main>
        <modal-footer @next-click="nextStep"></modal-footer>
    </div>
</template>
<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { useEventWizard } from '@/composables/useEventWizard';
import { CheckCircleIcon } from '@heroicons/vue/solid';
import { reactive } from 'vue';
import ModalFooter from '../ModalFooter.vue';

const { nextStep, pushFilter, getFilter } = useEventWizard();
const { filterOptions } = useCollectionFilters();
const categories = filterOptions.categories;

const selectedCategories = reactive(getFilter('categories') ?? []);

const store = () => {
    pushFilter(
        'categories',
        selectedCategories.length ? selectedCategories : null
    );
};
const categoryChange = (slug, event) => {
    if (selectedCategories.indexOf(slug) > -1) {
        const index = selectedCategories.indexOf(slug);
        selectedCategories.splice(index, 1);
    } else {
        selectedCategories.push(slug);
        selectedCategories.sort();
    }
    store();
};
const selectAll = () => {
    selectedCategories.splice(0, selectedCategories.length);
    categories.forEach((category) => {
        selectedCategories.push(category.slug);
    });
    store();
};
</script>

<template>
    <select v-model="model">
        <option v-if="!isRequired">Please Select...</option>
        <option
            v-for="(option, value) in options"
            :value="value"
            :selected="value === modelValue"
        >
            {{ option }}
        </option>
    </select>
</template>

<script setup>
import { DateTime } from 'luxon';
import { computed } from 'vue';
const model = defineModel({ required: true });

const props = defineProps({
    isRequired: {
        type: Boolean,
        required: false,
        default: false,
    },
    laterThan: {
        type: String,
        required: false,
        default: () => '00:00',
        validator(value) {
            const parts = value.split(':');
            return (
                parts.length === 2 &&
                Number.isInteger(parseInt(value.split(':')[0])) &&
                Number.isInteger(parseInt(value.split(':')[1]))
            );
        },
    },
});

const laterThanHour = computed(() => parseInt(props.laterThan.split(':')[0]));
const laterThanMinute = computed(() => parseInt(props.laterThan.split(':')[1]));

const fetchTimeOptions = () => {
    let times = {};
    let base = new DateTime({});
    for (let h = laterThanHour.value; h < 24; h++) {
        base = base.set({ hour: h });
        for (
            let m = h === laterThanHour.value ? laterThanMinute.value : 0;
            m < 60;
            m += 15
        ) {
            base = base.set({ minute: m });
            times[base.toLocaleString(DateTime.TIME_24_SIMPLE)] =
                base.toLocaleString(DateTime.TIME_SIMPLE);
        }
    }

    return times;
};

const options = fetchTimeOptions();
</script>

<style scoped></style>

<template>
    <span v-if="participants.max > 0">
        <template v-if="participants.min === participants.max">
            {{ participants.min }}
        </template>
        <template v-else>
            {{ participants.min }}&ndash;{{ participants.max }}
        </template>
        guests
    </span>
</template>

<script setup>
import { computed, inject } from 'vue';

const product = inject('product');
const participants = computed(() => product.participants);
</script>

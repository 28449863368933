import map from 'lodash/map';
import { isProxy, toRaw } from 'vue';

const isFalsy = (value) => {
    return [null, 'null', false, ''].includes(value);
};

export const usePushHistoryState = (_params, hash = null) => {
    let url = new URL(window.location);

    let params = {};
    for (const key in _params) {
        let value = _params[key];
        if (isProxy(value)) {
            value = toRaw(value);
        }
        if (Array.isArray(value)) {
            params[key] = value.join(',');
        } else {
            params[key] = value;
        }
        url.searchParams.set(key, params[key]);
    }

    const keysToDelete = [];
    url.searchParams.forEach(function (value, key) {
        if (!params.hasOwnProperty(key) || isFalsy(value)) {
            keysToDelete.push(key);
        }
    });
    keysToDelete.forEach((key) => url.searchParams.delete(key));

    if (hash) {
        url.hash = hash;
    }
    window.history.pushState(params, '', url);
};

export function clearUrlParams() {
    window.history.pushState({}, '', window.location.pathname);
}

export function deconstructUrlParams(
    arrayTypeParams = [],
    boolTypeParams = [],
    valueParams = []
) {
    let urlParams = new URLSearchParams(window.location.search);
    let params = {};
    urlParams.forEach(function (value, key) {
        if (!isFalsy(value)) {
            if (arrayTypeParams.includes(key)) {
                params[key] = map(value.split(','), (val) =>
                    !isNaN(Number(val)) ? Number(val) : val
                );
            } else if (boolTypeParams.includes(key)) {
                params[key] = value === 'true';
            } else if (valueParams.includes(key)) {
                params[key] = value;
            }
        }
    });

    return params;
}
